import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Flip } from "react-toastify";
import { css } from "glamor";
import icon from "../assets/icon.png";
import "./Register.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { Link, Redirect } from "react-router-dom";
import contentStrings from "../constants/localization";
import axios from "axios";
import constants from "../constants";
import {
  FormGroup,
  Col,
  Label,
  Input,
  FormText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";
import qs from "qs";
import ipfs from "../scripts/ipfs";
import FirebaseDBService from "../services/firebaseService";

//TODO: Controlled Inputs
//TODO: Handle failed transaction
//TODO: Update cotract to not allow duplicated accounts
//TODO: Change arrow functions in inputs to bind
//TODO: Send the correct arguments to the contract
//TODO: Display a web3 warning. if (this.props.web3.status === 'failed')

class Register extends Component {
  // Assuming we're observing the store for changes.

  constructor(props) {
    super(props);

    this.state = {
      country: "",
      region: "",
      name: "",
      email: "",
      accountType: constants.ROLES.FARMER,
      profileImageText: contentStrings.selectProfileImage,
      imageHash: "",
      bio: "",
      buffer: "",
      imageLoading: false,
      redirect: false,
      modal: false,
      transactionHash: "",
      modalSuccess: true,
      modalPending: true,
      modalBody: "",
      modalTitle: ""
    };

    this.contracts = this.props.drizzle.contracts;
    this.web3 = this.props.drizzle.web3;
    this.db = new FirebaseDBService();
    this.onChangeName = this.onChangeName.bind(this);
    this.onSelectAccountType = this.onSelectAccountType.bind(this);
    this.onSelectCountry = this.onSelectCountry.bind(this);
    this.onSelectRegion = this.onSelectRegion.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeBio = this.onChangeBio.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.modalToggle = this.modalToggle.bind(this);
    this.registerActor = this.registerActor.bind(this);
  }

  modalToggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  onChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  onChangeName(event) {
    this.setState({ name: event.target.value });
  }

  onChangeBio(event) {
    this.setState({ bio: event.target.value });
  }

  onSelectAccountType(event) {
    this.setState({ accountType: event.target.value });
  }

  onSelectCountry(value) {
    this.setState({ country: value });
  }

  onSelectRegion(value) {
    this.setState({ region: value });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  componentDidMount() {
    const { drizzle } = this.props;
    // subscribe to changes in the store
    this.unsubscribe = drizzle.store.subscribe(() => {
      // every time the store updates, grab the state from drizzle
      const drizzleState = drizzle.store.getState();
      // check to see if it's ready, if so, update local component state
      if (drizzleState.drizzleStatus.initialized) {
        if (drizzleState.transactionStack[this.state.transactionId]) {
          const transactionHash =
            drizzleState.transactionStack[this.state.transactionId];
          if (
            drizzleState.transactions[transactionHash].status === "pending" &&
            this.state.modalPending
          ) {
            this.setState({
              transactionHash: transactionHash,
              modal: true,
              modalTitle: contentStrings.modalSubmitedTitle,
              modalBody: contentStrings.modalSubmitedText,
              modalPending: false
            });
          }
          if (
            drizzleState.transactions[transactionHash].status === "success" &&
            this.state.modalSuccess
          ) {
            const id =
              drizzleState.transactions[transactionHash].receipt.events
                .LogAddActor.returnValues._id;

            this.setState({
              transactionHash: transactionHash,
              modal: true,
              modalTitle: contentStrings.modalSuccessTitle,
              modalBody: `${contentStrings.modalSuccessText} ${
                this.state.transactionHash
              }`,
              modalSuccess: false
            });
            const data = {
              address: this.props.drizzleState.accounts[0].toLowerCase(),
              name: this.state.name,
              typeOfActor: this.state.accountType,
              country: this.state.country,
              region: this.state.region,
              email: this.state.email,
              image_hash: this.state.imageHash,
              bio: this.state.bio,
              alloweds: [],
              allowers: []
            };
            this.setBoxInformation(data);
          }
        }
      }
    });
  }

  setBoxInformation = async (data) => {
    const redirect = true;
    const {address} = data;
    const newUser = await this.db.addAccount(address, data);
    if (!newUser.error){
      this.setState({ redirect });
    }
    else{
      console.error("Error creating account for address: "+ address);
    }
    
  };

  //Take file input from user
  //TODO: restrict only images
  captureFile = event => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ imageLoading: true });
    var profileImageText = contentStrings.selectProfileImage;
    if (event.target.files[0] != null) {
      profileImageText = event.target.files[0].name;
    }

    // this.setState({ profileImageText: "Loading..." });
    const file = event.target.files[0];
    if (file) {
      let reader = new window.FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = () => this.convertToBuffer(reader, profileImageText);
    } else {
      this.setState({
        profileImageText: contentStrings.selectProfileImage,
        buffer: "",
        imageLoading: false
      });
    }
  };

  //Convert the file to buffer to store on IPFS
  convertToBuffer = async (reader, profileImageText) => {
    //file is converted to a buffer for upload to IPFS
    this.setState({ profileImageText });
    const buffer = await Buffer.from(reader.result);
    //set this buffer-using es6 syntax
    this.setState({ buffer });
  };

  onFormSubmit = async event => {
    event.preventDefault();
    if (this.state.imageLoading) {
      let toastId = toast(`🏞️ ${contentStrings.uploadingImage}`, {
        position: "bottom-right",
        autoClose: false
      });
      await ipfs.add(this.state.buffer, (err, ipfsHash) => {
        toast.update(toastId, {
          render: `☕ ${contentStrings.uploadComplete}`,
          autoClose: true,
          autoClose: 1000,
          progressClassName: css({
            background: "linear-gradient(90deg, #332211, #cc7722, #774411)"
          })
        });
        this.setState({ imageHash: ipfsHash[0].hash });
        this.registerActor();
      });
    } else {
      this.registerActor();
    }
  };

  registerActor() {
    if (this.state.country !== "" && this.state.region !== "") {
      const stackId = this.contracts.ActorFactory.methods.addActor.cacheSend(
        this.web3.utils.utf8ToHex(this.state.accountType)
      );
      this.setState({ transactionId: stackId });
    }
  }

  render() {
    //TODO:change redirect to push
    if (this.state.redirect) {
      return <Redirect to="/login/accountCreated" />;
    }

    const { country, region } = this.state;
    return (
      <>
        <Modal
          isOpen={this.state.modal}
          toggle={this.modalToggle}
          size="lg"
          className={this.props.className}
        >
          <ModalHeader toggle={this.modalToggle}>
            {this.state.modalTitle}
          </ModalHeader>
          <ModalBody>{this.state.modalBody}</ModalBody>
          <ModalFooter>
            <Button className="btn btn-accent" onClick={this.modalToggle}>
              {contentStrings.close}
            </Button>
          </ModalFooter>
        </Modal>
        <div className="row no-gutters h-100">
          <div className="col-lg-5 col-md-5 auth-form mx-auto my-auto  ">
            <div className="card mt-4">
              <div className="card-body">
                <img
                  className="auth-form__logo d-table mx-auto mb-3"
                  src={icon}
                  alt="Logo"
                />
                <h5 className="auth-form__title text-center mb-4">
                  {contentStrings.createNewAccount}
                </h5>
                <form onSubmit={this.onFormSubmit}>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.accountAddress}</label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      aria-describedby=""
                      readOnly
                      value={this.props.drizzleState.accounts[0]}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.emailAddress}s</label>
                    <input
                      type="email"
                      className="form-control"
                      id=""
                      aria-describedby=""
                      placeholder={contentStrings.emailMessage}
                      onChange={this.onChangeEmail}
                      value={this.state.email}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.nameColumn}</label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder={contentStrings.nameColumn}
                      onChange={this.onChangeName}
                    />
                  </div>
                  <div className="form-group">
                    <Label for="">{contentStrings.profileImage}</Label>
                    <Input type="file" onChange={this.captureFile} />
                    <FormText color="muted">
                      {this.state.profileImageText}
                    </FormText>
                  </div>
                  <FormGroup>
                    <Label for="">{contentStrings.shortBio}</Label>
                    <Input
                      type="textarea"
                      placeholder={contentStrings.shortBioPlaceholder}
                      onChange={this.onChangeBio}
                    />
                  </FormGroup>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.accountType}</label>
                    <select
                      className="form-control"
                      value={this.state.accountType}
                      onChange={this.onSelectAccountType}
                    >
                      <option value="FARMER">{contentStrings.farmer}</option>
                      <option value="TASTER">{contentStrings.taster}</option>
                      <option value="COOPERATIVE">
                        {contentStrings.cooperative}
                      </option>
                      <option value="CERTIFIER">
                        {contentStrings.certifier}
                      </option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.countryColumn}</label>
                    <CountryDropdown
                      value={country}
                      classes="form-control"
                      onChange={this.onSelectCountry}
                      defaultOptionLabel={contentStrings.selectCountry}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.regionColumn}</label>
                    <RegionDropdown
                      classes="form-control"
                      country={country}
                      value={region}
                      onChange={this.onSelectRegion}
                      defaultOptionLabel={contentStrings.selectRegion}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-pill btn-accent d-table mx-auto"
                  >
                    {contentStrings.createNewAccount}
                  </button>
                </form>
              </div>
              <div className="card-footer border-top">
                <ul className="auth-form__social-icons d-table mx-auto">
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-github" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-google-plus-g" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="auth-form__meta d-flex mt-4 mb-4">
              <Link to="/Login" className="ml-auto mr-auto">
                {contentStrings.gotAccount}
              </Link>
            </div>
          </div>
        </div>
        <ToastContainer transition={Flip} />
      </>
    );
  }
}

export default Register;

import gql from "graphql-tag";


const cupProfileByID = gql`
  query cupProfiles ($cupProfileId: ID!) {
    cupProfile(id: $cupProfileId){
      id
      profile
      cuppingNote
      imageHash
      coffeeBatch {
        id
      }

    }
  }
`

const cupProfilesByProducer = gql`
    query coffeeBatches($actorId: ID!) {
       producer(id: $actorId){
         id
         coffeeBatches{
            id
            altitude
            variety
            process
            coffeeState
            size
            farm {
              id
              name
            }
            cupProfiles{
              id
            }
         }
       }
    }
`

export {
    cupProfileByID,
    cupProfilesByProducer
}
import React, { Component } from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import Register from "./components/Register";
import Login from "./components/Login";
import Loading from "./components/Loading";
import Web3Warning from "./components/Web3Warning";
import CoffeeBatchDetail from "./components/CoffeeBatchDetail";
import Dashboard from "./components/Dashboard";
import User from "./models/User";
import CheckAccountChanges from "./components/CheckAccountChanges";
import web3 from "web3";
import ProfileCard from "./components/ProfileCard";
import WrongNetworkWarning from "./components/WrongNetworkWarning";
import constants from "./constants";

import { Drizzle, generateStore } from "drizzle";
// Import contract
import ActorFactory from "./contracts/ActorFactory.json";
import FarmFactory from "./contracts/FarmFactory.json";
import CoffeeBatchFactory from "./contracts/CoffeeBatchFactory.json";
import CupProfileFactory from "./contracts/CupProfileFactory.json";
import CertificateFactory from "./contracts/CertificateFactory.json";
import ERC1155 from "./contracts/ERC1155.json";

class App extends Component {
  constructor(props) {
    super(props);
    let drizzle = false;
    // let drizzle know what contracts we want
    const options = {
      contracts: [
        ActorFactory,
        FarmFactory,
        CoffeeBatchFactory,
        CupProfileFactory,
        CertificateFactory,
        ERC1155
      ]
    };
    if (
      this.props.location.pathname.startsWith("/coffeeBatches/") ||
      this.props.location.pathname.startsWith("/actors/")
    ) {
      drizzle = false;
    } else {
      // setup the drizzle store and drizzle
      const drizzleStore = generateStore(options);
      drizzle = new Drizzle(options, drizzleStore);
    }
    this.state = { user: null, loading: true, drizzleState: null, drizzle };
  }

  onLogin = async userRaw => {
    var user = new User();
    await user.getData(
      userRaw,
      this.state.drizzleState.accounts[0],
      this.state.drizzle
    );
    this.setState({ user: user });
    localStorage.setItem("user", JSON.stringify(user));
    console.log("TCL: App -> user", user);
    this.props.history.push("/dashboard");
  };

  componentDidMount() {
    const { drizzle } = this.state;
    if (drizzle != false) {
      this.unsubscribe = drizzle.store.subscribe(async () => {
        // every time the store updates, grab the state from drizzle
        const drizzleState = drizzle.store.getState();
        if (
          drizzleState.web3.networkId != null &&
          drizzleState.web3.networkId != constants.NETWORK_ID
        ) {
          const networkType = await drizzle.web3.eth.net.getNetworkType();
          if (networkType != "private") {
            this.setState({ wrongNetwork: true });
          }
        }
        // check to see if it's ready, if so, update local component state
        if (drizzleState.drizzleStatus.initialized) {
          this.setState({ loading: false, drizzleState });
        }
      });
    }
    // subscribe to changes in the store
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    if (this.props.location.pathname.startsWith("/coffeeBatches/")) {
      return (
        <Route
          path="/coffeeBatches/:id"
          render={() => <CoffeeBatchDetail history={this.props.history} />}
        />
      );
    } else {
      if (this.props.location.pathname.startsWith("/actors/")) {
        return (
          <Route
            path="/actors/:id"
            render={() => <ProfileCard history={this.props.history} />}
          />
        );
      }
    }
    //If metamask is not installed or logged in
    if (
      web3.status === "failed" ||
      (!this.state.loading && this.state.drizzleState.accounts[0] == undefined)
    ) {
      return (
        // Display a web3 warning.
        <Web3Warning />
      );
    }
    //If wrong network
    if (this.state.wrongNetwork) {
      return (
        // Display a web3 warning.
        <WrongNetworkWarning />
      );
    }

    if (this.state.loading) {
      return <Loading />;
    } else {
      return (
        <>
          <CheckAccountChanges
            loggedAccount={this.state.drizzleState.accounts[0]}
            drizzle={this.state.drizzle}
            drizzleState={this.state.drizzleState}
          />
          <Switch>
            <Route
              path="/dashboard"
              render={() => (
                <Dashboard
                  user={this.state.user}
                  history={this.props.history}
                  drizzle={this.state.drizzle}
                  drizzleState={this.state.drizzleState}
                />
              )}
            />
            <Route
              path="/register"
              render={() => (
                <div className="container-fluid icon-sidebar-nav h-100">
                  <div className="row h-100">
                    <main className=" col">
                      <div className="main-content-container container-fluid px-4 my-auto h-100">
                        <Register
                          drizzle={this.state.drizzle}
                          drizzleState={this.state.drizzleState}
                        />
                      </div>
                    </main>
                  </div>
                </div>
              )}
            />
            <Route
              path="/login/:message"
              render={params => (
                <div className="container-fluid icon-sidebar-nav h-100">
                  <div className="row h-100">
                    <main className=" col">
                      <div className="main-content-container container-fluid px-4 my-auto h-100">
                        <Login
                          message={params.match.params.message}
                          onLogin={this.onLogin}
                          drizzle={this.state.drizzle}
                          drizzleState={this.state.drizzleState}
                        />
                      </div>
                    </main>
                  </div>
                </div>
              )}
            />
            <Route
              path="/"
              render={() => (
                <div className="container-fluid icon-sidebar-nav h-100">
                  <div className="row h-100">
                    <main className=" col">
                      <div className="main-content-container container-fluid px-4 my-auto h-100">
                        <Login
                          onLogin={this.onLogin}
                          drizzle={this.state.drizzle}
                          drizzleState={this.state.drizzleState}
                        />
                      </div>
                    </main>
                  </div>
                </div>
              )}
            />
          </Switch>
        </>
      );
    }
  }
}
export default withRouter(App);

import React, { Component } from "react";
import DataTable from "./DataTable";
import LinkButton from "./LinkButton";
import EmptyDashboardTable from "./EmptyDashboardTable";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import _ from "lodash";
import axios from "axios";
import constants from "../constants";
import contentStrings from "../constants/localization";
import CheckAccountPermissions from "./CheckAccountPermissions";

import { useQuery } from "@apollo/react-hooks";
import { producerFarms, cooperativeProducerFarms } from "./queries/Farm";
//TODO: Reload datatable on blockchain confirm
//TODO: show tostify

function LoadFarms({ accountAddress, isCooperative }) {
  const rows = [];
  const columns = [
    contentStrings.nameColumn,
    contentStrings.countryColumn,
    contentStrings.department,
    contentStrings.village,
    contentStrings.story,
    contentStrings.actionsColumn
  ];
  var linkUrl = `/dashboard/farms/edit/`;

  let graphQuery = producerFarms;
  if (isCooperative) {
    graphQuery = cooperativeProducerFarms;
    linkUrl = `/dashboard/producers/farms/edit/`;
  }

  const { loading, error, data } = useQuery(graphQuery, {
    variables: { actorAccount: accountAddress }
  });

  if (loading) return <Loading />;
  if (error) return <h2>ERROR</h2>;

  let actor = null;
  if (isCooperative) actor = data.cooperative;
  else actor = data.producer;

  if (actor != null) {
    if (isCooperative && actor.producers != null)
      actor.producers.map(producer =>
        producer.farms.map(farm => rows.push(getFarmRow({ farm, linkUrl })))
      );
    else actor.farms.map(farm => rows.push(getFarmRow({ farm, linkUrl })));

    return <DataTable id="currentBatches" columns={columns} rows={rows} />;
  } else
    return (
      <EmptyDashboardTable
        title={contentStrings.farms}
        titleSingular={contentStrings.farm}
        gender="f"
        url="farms"
      />
    );
}

function getFarmRow({ farm, linkUrl }) {
  let currentFarm = [];
  let currentUrl = linkUrl + `${farm.id}`;

  currentFarm.push(farm.name);
  currentFarm.push(farm.country);
  currentFarm.push(farm.region);
  currentFarm.push(farm.village);
  currentFarm.push(farm.story);
  currentFarm.push(<LinkButton url={currentUrl} iconClass={"fas fa-edit"} />);

  return currentFarm;
}

class Farms extends Component {
  constructor(props) {
    super(props);

    this.drizzle = props.drizzle;
    this.contracts = props.drizzle.contracts;
    this.web3 = props.drizzle.web3;

    const user = JSON.parse(localStorage.getItem("user"));

    this.state = {
      accountAddress: this.props.drizzleState.accounts[0],
      farms: null,
      status: "initialized",
      accountType: user.typeOfActor
    };
  }

  componentDidMount() {
    this.setState({ status: "complete" });
  }

  render() {
    let accountType = this.state.accountType;
    var isCooperative = accountType == constants.ROLES.COOPERATIVE;

    if (this.state.status == "waiting") {
      return <Loading />;
    }

    return (
      <>
        <CheckAccountPermissions />
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-center text-sm-left mb-4 mb-sm-0">
            <span className="text-uppercase page-subtitle">
              {contentStrings.overviewTitle}
            </span>
            <h3 className="page-title">{contentStrings.farms}</h3>
          </div>
          <div className="offset-sm-6 col-4 d-flex col-12 col-sm-2 d-flex align-items-center float-right">
            <div className="input-daterange  input-group-sm ml-auto">
              {!isCooperative ? (
                <LinkButton
                  url={"/dashboard/farms/new"}
                  iconClass={"fas fa-apple-alt mr-2 "}
                  caption={contentStrings.newFarm}
                />
              ) : (
                <div />
              )}
            </div>
          </div>
          <div className="row ">
            <div className="col mb-4">
              <div className="mb-3">
                <h6 className="mt-4">{contentStrings.resumeFarm}</h6>
              </div>

              <LoadFarms
                accountAddress={this.state.accountAddress.toLowerCase()}
                isCooperative={isCooperative}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Farms);

module.exports = Object.freeze({
  REST_URL: process.env.REACT_APP_REST_URL,
  IPFS_URL: process.env.REACT_APP_IPFS_URL,
  WEBSITE_URL: process.env.REACT_APP_WEBSITE_URL,
  NETWORK_ID: process.env.REACT_APP_NETWORK_ID,
  BOX_SPACE: process.env.REACT_APP_3BOX_SPACE,
  PINATA_KEY: process.env.REACT_APP_PINATA_API_KEY,
  PINATA_SECRET: process.env.REACT_APP_PINATA_SECRET,
  ROLES: {
    FARMER: "FARMER",
    COOPERATIVE: "COOPERATIVE",
    CERTIFIER: "CERTIFIER",
    TASTER: "TASTER"
  }
});

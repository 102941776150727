import React from "react";
import metamask from "../assets/metamask.png";
import "./Loading.css";
import contentStrings from "../constants/localization";

const Web3Warning = () => (
  <div className="loading-wrapper">
    <a href="metamask.io">
      <img src={metamask} alt="Empty..." className="shaking-icon" />
      <h6 className="mt-2">{contentStrings.web3Warning}.</h6>
    </a>
  </div>
);

export default Web3Warning;

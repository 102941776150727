import React, { Component } from "react";
import { Link } from "react-router-dom";
import contentStrings from "../constants/localization";
import CheckAccountPermissions from "./CheckAccountPermissions";

class EmptyDashboardTable extends Component {
  render() {
    var message;
    let buttonIcon = this.props.buttonIcon
      ? this.props.buttonIcon
      : "fas fa-apple-alt";
    if (this.props.message) {
      message = this.props.message;
    } else {
      if (this.props.gender == "f") {
        message = `${contentStrings.emptyDashboardMessage1} ${
          this.props.title
        } ${contentStrings.emptyDashboardMessage2F}.`;
      } else {
        message = `${contentStrings.emptyDashboardMessage1} ${
          this.props.title
        } ${contentStrings.emptyDashboardMessage2}.`;
      }
    }
    var buttonText;
    if (this.props.buttonText) {
      buttonText = this.props.buttonText;
    } else {
      if (this.props.gender == "f")
        buttonText = `${contentStrings.emptyDashboardButtonF} ${
          this.props.titleSingular
        }`;
      else
        buttonText = `${contentStrings.emptyDashboardButton} ${
          this.props.titleSingular
        }`;
    }
    return (
      <>
        <CheckAccountPermissions />
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-center text-sm-left mb-4 mb-sm-0">
            <span className="text-uppercase page-subtitle">
              {contentStrings.overviewTitle}
            </span>
            <h3 className="page-title">{this.props.title}</h3>
          </div>
          <div className="offset-sm-6 col-4 d-flex col-12 col-sm-2 d-flex align-items-center float-right" />
          <div className="row">
            <div className="col mb-4">
              <h4 className="mt-4">{message}</h4>
              {!this.props.removeButton ? (
                <div>
                  <Link
                    to={`/dashboard/${this.props.url}/new`}
                    className=""
                    onClick={this.props.onButtonClick}
                  >
                    <button type="button" className="btn btn-accent">
                      <i className={buttonIcon} />
                      <span className="ml-2">{buttonText}</span>
                    </button>
                  </Link>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EmptyDashboardTable;

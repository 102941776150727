import React, { Component } from "react";
import Blockies from "react-blockies";
import "./HeaderBar.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { clickMenuItem } from "../actions/index";
import contentStrings from "../constants/localization";
import constants from "../constants";
import { Link } from "react-router-dom";
import * as utils from "./utils/utils";

//TODO: On Signout remove store
class HeaderBar extends Component {
  constructor(props) {
    super(props);
    this.signOut = this.signOut.bind(this);
  }

  signOut(event) {
    this.props.setActiveItem(0);
    event.preventDefault();
    localStorage.removeItem("user");
    this.props.history.push("/login/signOut");
  }

  render() {
    return (
      <div className="main-navbar sticky-top bg-white">
        <nav className="navbar align-items-stretch navbar-light flex-md-nowrap p-0">
          <div className="main-navbar__search w-100 d-none d-md-flex d-lg-flex" />
          <ul className="navbar-nav border-left flex-row ">
            <li className="nav-item border-right dropdown notifications">
              <a
                className="nav-link nav-link-icon text-center"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="nav-link-icon__wrapper">
                  <i className="fas fa-bell" />
                </div>
              </a>
              <div
                className="dropdown-menu dropdown-menu-small"
                aria-labelledby="dropdownMenuLink"
              >
                <a className="dropdown-item" href="#">
                  <div className="notification__icon-wrapper">
                    <div className="notification__icon">
                      <i className="fas fa-check" />
                    </div>
                  </div>
                  <div className="notification__content">
                    <span className="notification__category">
                      {contentStrings.noNotificationsTitle}
                    </span>
                    <p>{contentStrings.noNotificationsMessage}</p>
                  </div>
                </a>
                <a
                  className="dropdown-item notification__all text-center"
                  href="#"
                >
                  {contentStrings.viewNotifications}
                </a>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle text-nowrap px-3"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {!!this.props.user.imageHash ? (
                  <img
                    src={`${constants.IPFS_URL}/${this.props.user.imageHash}`}
                    width="80"
                    className="user-avatar rounded-circle mr-2"
                  />
                ) : (
                  <Blockies
                    seed={this.props.drizzleState.accounts[0]}
                    size={8}
                    scale={10}
                    className="identicon user-avatar rounded-circle mr-2"
                  />
                )}
                <span className="d-none d-md-inline-block">
                  {!!this.props.user.name
                    ? this.props.user.name
                    : utils.addressShortener(
                        this.props.drizzleState.accounts[0]
                      )}
                </span>
              </a>
              <div className="dropdown-menu dropdown-menu-small">
                <Link to="/dashboard/profile" className="dropdown-item">
                  <i className="fas fa-user" />
                  <span className="ml-2">{contentStrings.profile}</span>
                </Link>
                <div className="dropdown-divider" />
                <a
                  className="dropdown-item text-danger"
                  href=""
                  onClick={this.signOut}
                >
                  <i className="fas fa-sign-out-alt" />
                  <span className="ml-2">{contentStrings.signOut}</span>
                </a>
              </div>
            </li>
          </ul>
          {/* TODO: On click add class open to side bar*/}
          <nav className="nav">
            <a
              href="#"
              className="nav-link nav-link-icon toggle-sidebar d-md-inline d-lg-none text-center border-left"
              data-toggle="collapse"
              data-target=".header-navbar"
              aria-expanded="false"
              aria-controls="header-navbar"
            >
              <i className="material-icons">&#xE5D2;</i>
            </a>
          </nav>
        </nav>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setActiveItem: activeMenuItem => dispatch(clickMenuItem(activeMenuItem))
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(HeaderBar)
);

import React, { Component } from "react";
import { withRouter } from "react-router";
import coffee from "../assets/coffee.jpg";
import "./CoffeeBatchDetail.css";
import "./CoffeeCard.css";
import contentStrings from "../constants/localization";
import Blockies from "react-blockies";
import { InputGroupAddon, Input, InputGroup, Button } from "reactstrap";
import QRCode from "qrcode.react";
import constants from "../constants";
import axios from "axios";
import Loading from "./Loading";
import NotFound from "./NotFound";
import FirebaseDBService from "../services/firebaseService";
class ProfileCard extends Component {
  constructor(props) {
    super(props);
    this.db = new FirebaseDBService();
    this.state = {
      actor: null,
      status: "initialized"
    };
  }

  async componentDidMount() {
    try {
      const actor = await this.db.getAccount(this.props.match.params.id);
      this.setState({
        actor,
        status: "complete"
      });
    } catch (e) {
      console.error(e);
      this.setState({
        status: "complete"
      });
    }
  }

  render() {
    if (this.state.actor == null && this.state.status == "complete") {
      return <NotFound message={contentStrings.coffeeBatch404} />;
    }
    if (this.state.status == "initialized") {
      return <Loading />;
    }
    return (
      <>
        <div className="row">
          <main className="coffee-batch-detail main-content col-lg-10 col-md-9 col-sm-12 p-0 ">
            <div className="main-content-container container-fluid px-4">
              <div className="page-header row no-gutters py-4">
                <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                  <span className="text-uppercase page-subtitle">
                    {contentStrings.details}
                  </span>
                  <h3 className="page-title">{contentStrings.profile}</h3>
                </div>
              </div>

              <div className="row mt-0">
                <div className="col-sm-12 col-lg-4">
                  <div className="card card-small user-details mb-4">
                    <div className="card-header p-0">
                      <div className="user-details__bg">
                        <img
                          src={coffee}
                          alt="Coffee Details Background Image"
                        />
                      </div>
                    </div>
                    <div className="card-body p-0 pt-3">
                      <div className="user-details__avatar mx-auto">
                        {this.state.actor.image_hash != "" ? (
                          <img
                            src={`${constants.IPFS_URL}/${
                              this.state.actor.image_hash
                            }`}
                            width="80"
                            className="user-avatar rounded-circle mr-2"
                          />
                        ) : (
                          <Blockies
                            seed={this.state.actor.address}
                            size={8}
                            scale={10}
                            className="identicon user-avatar rounded-circle mr-2"
                          />
                        )}
                      </div>
                      <div className="user-details__user-data p-4 text-center">
                        <div className="row mb-3">
                          <div className="col w-50">
                            <span>{contentStrings.nameColumn}</span>
                            <span>{this.state.actor.name} </span>
                          </div>
                        </div>
                        {this.state.actor.bio != null && (
                          <div className="row mb-3">
                            <div className="col">
                              <span>{contentStrings.shortBio}</span>
                              <span>{this.state.actor.bio}</span>
                            </div>
                          </div>
                        )}
                        <div className="row mb-3">
                          <div className="col">
                            <span>{contentStrings.regionColumn}</span>
                            <span>
                              {this.state.actor.region},{" "}
                              {this.state.actor.country}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col">
                            <span>{contentStrings.contactInformation}</span>
                            <span>{this.state.actor.email}</span>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col">
                            <span>{contentStrings.accountAddress}</span>
                            <span>
                              <InputGroup>
                                <InputGroupAddon
                                  addonType="append"
                                  className="mx-auto"
                                >
                                  <Input
                                    readOnly
                                    value={this.state.actor.address}
                                  />
                                  <Button
                                    className="btn btn-accent"
                                    onClick={this.copyText}
                                  >
                                    {contentStrings.copy}
                                  </Button>
                                </InputGroupAddon>
                              </InputGroup>
                            </span>
                          </div>
                        </div>
                        <div className="user-details__tags  text-center m-2 mt-2">
                          <QRCode
                            id="downloadCanvas"
                            size={230}
                            value={this.state.actor.address}
                            className="m-3"
                          />
                        </div>
                        <div className="user-details__tags  text-center m-2 mt-2">
                          <a
                            className="btn burner-button "
                            href={`https://xdai.io/${
                              this.state.actor.address
                            };1;tip%20the%20actor`}
                            target="blank"
                          >
                            <i
                              className={`fas fa-fire ${this.props.iconClass}`}
                            />{" "}
                            {contentStrings.tipBurner}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <footer className="row main-footer d-flex p-2 px-3 bg-white border-top">
          <ul className="nav">
            <li className="nav-item">
              <a className="nav-link" href="#">
                {contentStrings.home}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                {contentStrings.services}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                {contentStrings.about}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                {contentStrings.products}
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                {contentStrings.blog}
              </a>
            </li>
          </ul>
          <span className="copyright ml-auto my-auto mr-2">
            Copyright © 2018
            <a href="#" rel="nofollow" className="ml-2">
              {contentStrings.companyName}
            </a>
          </span>
        </footer>
      </>
    );
  }
}

export default withRouter(ProfileCard);

import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import Sidebar from "./Sidebar";
import HeaderBar from "./HeaderBar";
import NewCoffeeBatch from "./NewCoffeeBatch";
import NewFarm from "./NewFarm";
import Farms from "./Farms";
import CoffeeBatches from "./CoffeeBatches";
import Certificates from "./Certificates";
import NewCertificate from "./NewCertificate";
import Alloweds from "./Alloweds";
import NewCupProfile from "./NewCupProfile";
import CupProfiles from "./CupProfiles";
import AssignCertificate from "./AssignCertificate";
import contentStrings from "../constants/localization";
import { connect } from "react-redux";
import { setAccountType } from "../actions/index";
import Producers from "./Producers";
import NewProducer from "./NewProducer";
import NewFarmProducer from "./NewFarmProducer";
import NewProducerCoffeeBatch from "./NewProducerCoffeeBatch";
import ProducerAlloweds from "./ProducerAlloweds";
import UpdateProfile from "./UpdateProfile";
import { ToastContainer } from "react-toastify";
import { Flip } from "react-toastify";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem("user"));
    this.contracts = props.drizzle.contracts;
    this.web3 = props.drizzle.web3;

    if (user == null) {
      this.props.history.push("/login");
    } else {
      this.state = {
        drizzle: props.drizzle,
        user: JSON.parse(localStorage.getItem("user")),
        accountType: user.typeOfActor
      };
      this.props.setAccountType(user.typeOfActor);
    }
  }

  render() {
    const { accounts } = this.props;
    if (this.state != null) {
      return (
        <div className="row ">
          <Sidebar />
          <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
            <HeaderBar
              user={this.state.user}
              drizzleState={this.props.drizzleState}
            />
            <div className="main-content-container container-fluid px-4 h-100">
              <Switch>
                <Route
                  path="/dashboard/profile/"
                  render={() => (
                    <UpdateProfile
                      title={contentStrings.updateProfile}
                      drizzle={this.props.drizzle}
                      drizzleState={this.props.drizzleState}
                    />
                  )}
                />
                <Route
                  path="/dashboard/producers/:id/permissions"
                  render={() => (
                    <ProducerAlloweds
                      drizzle={this.props.drizzle}
                      drizzleState={this.props.drizzleState}
                    />
                  )}
                />
                <Route
                  path="/dashboard/producers/:id/coffeeBatches/new"
                  render={() => (
                    <NewProducerCoffeeBatch
                      title={contentStrings.createCoffeeBatch}
                      drizzle={this.props.drizzle}
                      drizzleState={this.props.drizzleState}
                    />
                  )}
                />
                <Route
                  path="/dashboard/producers/:producerId/coffeeBatches/edit/:id"
                  render={() => (
                    <NewProducerCoffeeBatch
                      isUpdate="true"
                      title={contentStrings.updateCoffeeBatch}
                      drizzle={this.props.drizzle}
                      drizzleState={this.props.drizzleState}
                    />
                  )}
                />
                <Route
                  path="/dashboard/producers/:id/farms/new"
                  render={() => (
                    <NewFarmProducer
                      title={contentStrings.createFarm}
                      drizzle={this.props.drizzle}
                      drizzleState={this.props.drizzleState}
                    />
                  )}
                />
                <Route
                  path="/dashboard/producers/:producerId/farms/edit/:id"
                  render={() => (
                    <NewFarmProducer
                      isUpdate="true"
                      title={contentStrings.updateFarm}
                      drizzle={this.props.drizzle}
                      drizzleState={this.props.drizzleState}
                    />
                  )}
                />
                <Route
                  path="/dashboard/producers/new"
                  render={() => (
                    <NewProducer
                      title={contentStrings.newProducer}
                      drizzle={this.props.drizzle}
                      drizzleState={this.props.drizzleState}
                    />
                  )}
                />
                <Route
                  path="/dashboard/producers"
                  render={() => (
                    <Producers
                      drizzle={this.props.drizzle}
                      drizzleState={this.props.drizzleState}
                    />
                  )}
                />
                <Route
                  path="/dashboard/farms/edit/:id"
                  render={() => (
                    <NewFarm
                      isUpdate="true"
                      title={contentStrings.updateFarm}
                      drizzle={this.props.drizzle}
                      drizzleState={this.props.drizzleState}
                    />
                  )}
                />
                <Route
                  path="/dashboard/farms/new"
                  render={() => (
                    <NewFarm
                      title={contentStrings.createFarm}
                      drizzle={this.props.drizzle}
                      drizzleState={this.props.drizzleState}
                    />
                  )}
                />
                <Route
                  path="/dashboard/farms"
                  render={() => (
                    <Farms
                      drizzle={this.props.drizzle}
                      drizzleState={this.props.drizzleState}
                      accountType={this.state.accountType}
                    />
                  )}
                />
                <Route
                  path="/dashboard/coffeeBatches/edit/:id"
                  render={() => (
                    <NewCoffeeBatch
                      drizzle={this.props.drizzle}
                      drizzleState={this.props.drizzleState}
                      isUpdate="true"
                      title={contentStrings.updateCoffeeBatch}
                    />
                  )}
                />
                <Route
                  path="/dashboard/coffeeBatches/:coffeeBatchId/cupProfiles/new"
                  render={() => (
                    <NewCupProfile
                      accounts={accounts}
                      title={contentStrings.createCupProfile}
                      drizzle={this.props.drizzle}
                      drizzleState={this.props.drizzleState}
                    />
                  )}
                />
                <Route
                  path="/dashboard/coffeeBatches/:coffeeBatchId/certificates/assign"
                  render={() => (
                    <AssignCertificate
                      accounts={accounts}
                      title={contentStrings.assignCertificate}
                      drizzle={this.props.drizzle}
                      drizzleState={this.props.drizzleState}
                    />
                  )}
                />
                <Route
                  path="/dashboard/coffeeBatches/new"
                  render={() => (
                    <NewCoffeeBatch
                      drizzle={this.props.drizzle}
                      drizzleState={this.props.drizzleState}
                      title={contentStrings.createCoffeeBatch}
                    />
                  )}
                />
                <Route
                  path="/dashboard/coffeeBatches"
                  render={() => (
                    <CoffeeBatches
                      drizzle={this.props.drizzle}
                      drizzleState={this.props.drizzleState}
                      accountType={this.state.accountType}
                    />
                  )}
                />
                <Route
                  path="/dashboard/permissions"
                  render={() => (
                    <Alloweds
                      drizzle={this.props.drizzle}
                      drizzleState={this.props.drizzleState}
                    />
                  )}
                />
                <Route
                  path="/dashboard/cupProfiles/edit/:id"
                  render={() => (
                    <NewCupProfile
                      drizzle={this.props.drizzle}
                      drizzleState={this.props.drizzleState}
                      isUpdate="true"
                      title={contentStrings.cupProfile}
                    />
                  )}
                />
                <Route
                  path="/dashboard/cupProfiles"
                  render={() => (
                    <CupProfiles
                      drizzle={this.props.drizzle}
                      drizzleState={this.props.drizzleState}
                    />
                  )}
                />
                <Route
                  path="/dashboard/certificates/new"
                  render={() => (
                    <NewCertificate
                      title={contentStrings.createCertificate}
                      drizzle={this.props.drizzle}
                      drizzleState={this.props.drizzleState}
                    />
                  )}
                />
                <Route
                  path="/dashboard/certificates"
                  render={() => (
                    <Certificates
                      drizzle={this.props.drizzle}
                      drizzleState={this.props.drizzleState}
                      accountType={this.state.accountType}
                    />
                  )}
                />
              </Switch>
            </div>
          </main>
          <ToastContainer transition={Flip} />
        </div>
      );
    } else {
      return <div />;
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setAccountType: accountType => dispatch(setAccountType(accountType))
  };
};

const mapStateToProps = state => {
  return { store: state };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Dashboard)
);

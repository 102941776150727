import React, { Component } from "react";
import icon from "../assets/icon.png";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Flip } from "react-toastify";
import { css } from "glamor";
import contentStrings from "../constants/localization";

//TODO: Add metamask logo
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drizzle: this.props.drizzle,
      onLogin: ""
    };
    this.contracts = this.props.drizzle.contracts;
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.message != "") {
      this.showMessage();
    }
  }

  onFormSubmit(event) {
    event.preventDefault();
    try {
      var rawData;
      const actor = this.contracts.ActorFactory;
      const props = this.props;
      const getData = async function() {
        const elem = await actor.methods
          .getSenderRole()
          .call({ from: props.drizzleState.accounts[0] })
          .then(
            data => {
              return data;
            },
            error => {
              console.log(error.message);
              toast(`❌ ${contentStrings.loginErrorMessage}`, {
                progressClassName: css({
                  background:
                    "linear-gradient(90deg, #B70303, #FF0505, #F95204)"
                })
              });
            }
          );
        rawData = elem;
      };
      getData().then(function() {
        if (
          rawData != undefined &&
          rawData !=
            "0x0000000000000000000000000000000000000000000000000000000000000000"
        ) {
          props.onLogin(rawData);
        } else {
          toast(`❌ ${contentStrings.loginErrorMessage}`, {
            progressClassName: css({
              background: "linear-gradient(90deg, #B70303, #FF0505, #F95204)"
            })
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  showMessage = () => {
    switch (this.props.message) {
      case "accountCreated":
        toast(`☕ ${contentStrings.accountCreatedMessage}`, {
          progressClassName: css({
            background: "linear-gradient(90deg, #332211, #cc7722, #774411)"
          })
        });
        break;
      case "signOut":
        toast(`🚪 ${contentStrings.signOutMessage}`, {
          progressClassName: css({
            background: "linear-gradient(90deg, #332211, #cc7722, #774411)"
          })
        });
      default:
        break;
    }
  };

  render() {
    return (
      <div className="row no-gutters h-100">
        <div className="col-lg-4 col-md-5 auth-form mx-auto my-auto">
          <div className="card">
            <div className="card-body">
              <img
                className="auth-form__logo d-table mx-auto mb-3"
                src={icon}
                alt="Affogato Network"
              />
              <h5 className="auth-form__title text-center mb-4">
                {contentStrings.loginTitle}
              </h5>
              <form onSubmit={this.onFormSubmit}>
                <div className="form-group">
                  <label htmlFor="">{contentStrings.loginLabel}</label>
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    aria-describedby=""
                    readOnly
                    value={this.props.drizzleState.accounts[0]}
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-pill btn-accent d-table mx-auto"
                >
                  {contentStrings.loginButton}
                </button>
              </form>
            </div>
            <div className="card-footer border-top">
              <ul className="auth-form__social-icons d-table mx-auto">
                <li>
                  <a href="#">
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-github" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-google-plus-g" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="auth-form__meta d-flex mt-4">
            <Link to="/Register" className="ml-auto mr-auto">
              {contentStrings.registerLink}
            </Link>
          </div>
        </div>
        <ToastContainer transition={Flip} />
      </div>
    );
  }
}

export default Login;

import React, { Component } from "react";
import empty from "../assets/coffee-glass-empty.png";
import "./Loading.css";

class NotFound extends Component {
  render() {
    return (
      <div className="loading-wrapper">
        <img src={empty} alt="Empty..." className="shaking-icon" />
        <h4 className="mt-2">{this.props.message}</h4>
      </div>
    );
  }
}

export default NotFound;

import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import constants from "../constants";

//TODO: If account changes delete session
//TODO: if account not equal logged delete session
class CheckAccountPermissions extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let activeItem = 0;

    if (this.props.store.accountType == constants.ROLES.FARMER) {
      if (this.props.location.pathname.includes("farms")) {
        activeItem = 1;
      }

      if (this.props.location.pathname.includes("coffeeBatches")) {
        activeItem = 2;
      }
      if (this.props.location.pathname.includes("permissions")) {
        activeItem = 3;
      }

      if (this.props.location.pathname.includes("cupProfiles")) {
        activeItem = 4;
      }
    }

    if (this.props.store.accountType == constants.ROLES.COOPERATIVE) {
      if (this.props.location.pathname.includes("producers")) {
        activeItem = 1;
      }
      if (this.props.location.pathname.includes("farms")) {
        activeItem = 2;
      }

      if (this.props.location.pathname.includes("coffeeBatches")) {
        activeItem = 3;
      }
      if (this.props.location.pathname.includes("permissions")) {
        activeItem = 4;
      }
    }

    if (this.props.store.accountType == constants.ROLES.TASTER) {
      if (this.props.location.pathname.includes("cupProfiles")) {
        activeItem = 1;
      }
    }

    if (this.props.store.accountType == constants.ROLES.CERTIFIER) {
      if (this.props.location.pathname.includes("certificates")) {
        activeItem = 1;
      }
    }

    if (
      (this.props.location.pathname != "/dashboard/" ||
        this.props.location.pathname != "/dashboard") &&
      activeItem == 0
    ) {
      this.props.history.push(`/dashboard/`);
      window.location.reload();
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => {
  return { store: state };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(CheckAccountPermissions)
);

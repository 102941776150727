import { SIDEBAR_ITEM_CLICK, USER_LOGIN } from "../constants/action-types";
const initialState = 0;

const rootReducer = (state = initialState, action) => {
  console.log(action);
  switch (action.type) {
    case SIDEBAR_ITEM_CLICK:
      return Object.assign({}, state, {
        activeMenuItem: action.payload
      });
    case USER_LOGIN:
      return Object.assign({}, state, {
        accountType: action.payload
      });
    default:
      return state;
  }
};
export default rootReducer;

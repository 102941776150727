import React, { Component } from "react";
import DataTable from "./DataTable";
import EmptyDashboardTable from "./EmptyDashboardTable";
import LinkButton from "./LinkButton";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import _ from "lodash";
import axios from "axios";
import QRCode from "qrcode.react";
import Modal from "react-awesome-modal";
import copy from "copy-to-clipboard";
import constants from "../constants";
import contentStrings from "../constants/localization";
import { connect } from "react-redux";
import CheckAccountPermissions from "./CheckAccountPermissions";
import { coffeeBatchProcesses, coffeeBatchStates } from "../constants/choices";

import { useQuery } from "@apollo/react-hooks";
import { tasterCoffeeBatches } from "./queries/CoffeeBatch"
import { cupProfilesByProducer } from "./queries/CupProfile"


function LoadCupProfiles({ accountAddress, isTaster }){
    const columns = [
      contentStrings.farm,
      contentStrings.variety,
      contentStrings.altitude,
      contentStrings.process,
      contentStrings.batchSize,
      contentStrings.actionsColumn
    ];
    var rows = [];
    var gqlQuery = cupProfilesByProducer;
    if (isTaster)
        gqlQuery = tasterCoffeeBatches;


    const { loading, error, data } = useQuery(gqlQuery, {
        variables: { actorId: accountAddress },
    });
    if (loading) return <Loading />;
    if (error) return <h2>ERROR</h2>;

    let actor = null;
    if (isTaster){
        if (data.taster != null && data.taster.producers != null)
            rows = GetTasteCupProfiles(data.taster);
    }
    else{
        if (data.producer != null && data.producer.coffeeBatches != null){
            rows = GetProducerCupProfiles(data.producer);
        }
    }

    if (rows.length > 0)
        return <DataTable id="currentBatches" columns={columns} rows={rows} />;
    else
        return (
            <EmptyDashboardTable
              title={contentStrings.cupProfiles}
              titleSingular={contentStrings.cupProfile}
              gender="f"
              url="farms"
              removeButton={true}
              message={contentStrings.emptyTasting}
            />
          );

}

function GetProducerCupProfiles(producer){
    const rows = [];
    var currentCupProfile = [];
    for (const [j, coffeeBatch] of producer.coffeeBatches.entries()) {
        for (const [j, cupProfile] of coffeeBatch.cupProfiles.entries()) {
            var addCoffeeBatch = true;
            var cupProfileId = 0;
            currentCupProfile = [];

            currentCupProfile.push(coffeeBatch.farm.name);
            currentCupProfile.push(coffeeBatch.variety);
            currentCupProfile.push(coffeeBatch.altitude);
            currentCupProfile.push(coffeeBatchProcesses[coffeeBatch.process]);
            currentCupProfile.push(coffeeBatch.size / 100);
            currentCupProfile.push(<LinkButton url={`/dashboard/cupProfiles/edit/${cupProfile.id}`} iconClass={"fas fa-eye"} />);
            rows.push(currentCupProfile);
        }
      }

      return rows;
}

function GetTasteCupProfiles(taster){
    const rows = [];
    var currentCupProfile = [];
    for (const [i, producer] of taster.producers.entries()) {
      for (const [j, coffeeBatch] of producer.coffeeBatches.entries()) {
        var addCoffeeBatch = true;
        var cupProfileId = 0;
        currentCupProfile = [];

        if (coffeeBatch.cupProfiles.length > 0){
            for (const [k, cupProfile] of coffeeBatch.cupProfiles.entries()) {
                if (taster.id == cupProfile.taster.id)
                    cupProfileId = cupProfile.id;
            }
        }

        currentCupProfile.push(coffeeBatch.farm.name);
        currentCupProfile.push(coffeeBatch.variety);
        currentCupProfile.push(coffeeBatch.altitude);
        currentCupProfile.push(coffeeBatchProcesses[coffeeBatch.process]);
        currentCupProfile.push(coffeeBatch.size / 100);
        if (cupProfileId == 0)
            currentCupProfile.push(<LinkButton url={`/dashboard/coffeeBatches/${coffeeBatch.id}/cupProfiles/new`} iconClass={"fas fa-plus"} />);
        else
            currentCupProfile.push(<LinkButton url={`/dashboard/cupProfiles/edit/${cupProfileId}`} iconClass={"fas fa-eye"} />);
        rows.push(currentCupProfile);
      }
    }

    return rows;
}


class CupProfiles extends Component {
  constructor(props) {
    super(props);

    this.drizzle = props.drizzle;
    this.contracts = props.drizzle.contracts;
    this.web3 = props.drizzle.web3;
    const user = JSON.parse(localStorage.getItem("user"));

    this.state = {
      accountAddress: this.props.drizzleState.accounts[0].toLowerCase(),
      accountType: user.typeOfActor,
      status: "initialized"
    };
  }

  componentDidMount() {
    this.setState({ status: "complete" });
  }

  render() {
    if (this.state.status == "waiting") {
      return <Loading />;
    }

    let isTaster = (this.state.accountType == constants.ROLES.TASTER);

    return (
      <>
        <CheckAccountPermissions />
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-center text-sm-left mb-4 mb-sm-0">
            <span className="text-uppercase page-subtitle">
              {contentStrings.overviewTitle}
            </span>
            <h3 className="page-title">{contentStrings.cupProfiles}</h3>
          </div>
          <div className="offset-sm-6 col-4 d-flex col-12 col-sm-2 d-flex align-items-center float-right">
            <div className="input-daterange  input-group-sm ml-auto" />
          </div>
          <div className="row ">
            <div className="col mb-4">
              <div className="mb-3">
                <h6 className="mt-4">{contentStrings.resumeCupProfiles}</h6>
              </div>

              <LoadCupProfiles accountAddress={this.state.accountAddress} isTaster={isTaster} />
            </div>
          </div>
        </div>
      </>
    );
  }

}

export default withRouter(CupProfiles);

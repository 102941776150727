import React from "react";
import loading from "../assets/coffee-glass.png";
import "./Loading.css";
import contentStrings from "../constants/localization";

const Loading = () => (
  <div className="loading-wrapper">
    <img src={loading} alt="loading..." className="breathing-icon" />
    <h4>{contentStrings.loading}...</h4>
  </div>
);

export default Loading;

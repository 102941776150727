import React from "react";
import Loading from "./Loading";
import { Label } from "reactstrap";
import contentStrings from "../constants/localization";
import { useQuery } from "@apollo/react-hooks";
import { producerFarms } from "./queries/Farm"


function ProducerFarmsSelect({ producerAccount, onChangeSelectedFarm, selectedFarm }){
    const { loading, error, data } = useQuery(producerFarms, {
        variables: { actorAccount: producerAccount.toLowerCase() }
    });

    if (loading) return <Loading />;
    if (error) return <h2>ERROR</h2>;

    return (
        <div className="form-group">
            <Label for="">{contentStrings.farm}</Label>
            <select
              id="inputState"
              className="form-control  mb-3"
              onChange={onChangeSelectedFarm}
              value={selectedFarm}
            >
               <option value="0">{contentStrings.selectFarm}</option>
               {data.producer.farms != null ? (
                   data.producer.farms.map(farm => (
                        <option value={farm.id} key={farm.id}>
                            {farm.name}
                        </option>
                    ))
               ) : (<></>)}
            </select>
        </div>);
}

export default ProducerFarmsSelect;
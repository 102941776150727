import React, { Component } from "react";
import { withRouter } from "react-router";
import Loading from "./Loading";
import DataTable from "./DataTable";
import axios from "axios";
import _ from "lodash";
import QRCode from "qrcode.react";
import constants from "../constants";
import contentStrings from "../constants/localization";
import CheckAccountPermissions from "./CheckAccountPermissions";
import qs from "qs";
import {
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";

class AssignCertificate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      coffeeBatch: null,
      owner: null,
      status: "initialized",
      certificates: null,
      selectedCertificate: "",
      modal: false,
      transactionHash: "",
      modalSuccess: true,
      modalPending: true,
      modalBody: "",
      modalTitle: ""
    };

    this.drizzle = props.drizzle;
    this.contracts = props.drizzle.contracts;
    this.web3 = props.drizzle.web3;

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChangeSelectedCertificate = this.onChangeSelectedCertificate.bind(
      this
    );
    this.modalToggle = this.modalToggle.bind(this);
  }

  modalToggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onChangeSelectedCertificate(event) {
    this.setState({ selectedCertificate: event.target.value });
  }

  async componentDidMount() {
    const { drizzle } = this.props;
    // subscribe to changes in the store
    this.unsubscribe = drizzle.store.subscribe(() => {
      // every time the store updates, grab the state from drizzle
      const drizzleState = drizzle.store.getState();

      // check to see if it's ready, if so, update local component state
      if (drizzleState.drizzleStatus.initialized) {
        if (drizzleState.transactionStack[this.state.transactionId]) {
          const transactionHash =
            drizzleState.transactionStack[this.state.transactionId];
          if (
            drizzleState.transactions[transactionHash].status == "pending" &&
            this.state.modalPending
          ) {
            this.setState({
              transactionHash: transactionHash,
              modal: true,
              modalTitle: contentStrings.modalSubmitedTitle,
              modalBody: contentStrings.modalSubmitedText,
              modalPending: false
            });
          }
          if (
            drizzleState.transactions[transactionHash].status == "success" &&
            this.state.modalSuccess
          ) {
            const id =
              drizzleState.transactions[transactionHash].receipt.events
                .LogAssignCertificate.returnValues._certificateId;

            this.setState({
              transactionHash: transactionHash,
              modal: true,
              modalTitle: contentStrings.modalSuccessTitle,
              modalBody: `${contentStrings.modalSuccessText} ${
                this.state.transactionHash
              }`,
              modalSuccess: false
            });

            const url = `${constants.REST_URL}/coffeeBatches/${
              this.props.match.params.coffeeBatchId
            }/certificates`;
            const method = "POST";
            const data = {
              certificate_id: id
            };
            const options = {
              method: method,
              headers: {
                "content-type": "application/x-www-form-urlencoded"
              },
              data: qs.stringify(data),
              url
            };
            axios(options)
              .then(response => {
                this.props.history.push(`/dashboard/certificates`);
              })
              .catch(function(error) {
                console.log(error);
              });
          }
        }
      }
    });

    //GET Data
    try {
      const response = await axios.get(
        `${constants.REST_URL}/coffeeBatches/${
          this.props.match.params.coffeeBatchId
        }`
      );

      var coffeeBatch = null;
      if (response) {
        coffeeBatch = response.data;
      }
      const responseActor = await axios.get(
        `${constants.REST_URL}/actors/id/${coffeeBatch.owner_id}`
      );
      var actor = null;
      if (responseActor) {
        actor = responseActor.data;
      }

      const responseCertificates = await axios.get(
        `${constants.REST_URL}/actors/${
          this.props.drizzleState.accounts[0]
        }/certificates`
      );
      var certificates = null;
      if (responseCertificates) {
        certificates = responseCertificates.data;
      }

      this.setState({
        coffeeBatch: coffeeBatch,
        owner: actor,
        certificates: certificates,
        status: "complete"
      });
    } catch (error) {
      console.log(error);
      this.setState({ status: "complete" });
    }
  }

  onFormSubmit = async event => {
    event.preventDefault();
    const stackId = this.contracts.CertificateFactory.methods.assignCertificate.cacheSend(
      this.state.owner.address,
      this.props.match.params.coffeeBatchId,
      this.state.selectedCertificate,
      { from: this.props.drizzleState.accounts[0] }
    );
    this.setState({ transactionId: stackId });
  };

  render() {
    if (this.state.status == "waiting" || this.state.status == "initialized") {
      return <Loading />;
    }

    if (this.state.coffeeBatch == null) {
      //TODO: Do something if coffee batch doesnt exist
      return <></>;
    }
    //TODO: refactor this
    const columns = [
      contentStrings.qrCode,
      contentStrings.farm,
      contentStrings.variety,
      contentStrings.altitude,
      contentStrings.process,
      contentStrings.batchSize
    ];
    var rows = [];
    const coffeeBatch = this.state.coffeeBatch;

    var currentCoffeeBatch = [];
    currentCoffeeBatch.push(
      <div className="mt-2">
        <QRCode
          size={80}
          value={`${constants.REST_URL}/coffeeBatches/${coffeeBatch.id}`}
        />
      </div>
    );
    currentCoffeeBatch.push(coffeeBatch.farm_name);
    currentCoffeeBatch.push(coffeeBatch.variety);
    currentCoffeeBatch.push(coffeeBatch.altitude);
    currentCoffeeBatch.push(coffeeBatch.process);
    currentCoffeeBatch.push(coffeeBatch.size / 100);
    rows.push(currentCoffeeBatch);

    //TODO: remove header and footer from data table
    return (
      <>
        <CheckAccountPermissions />
        <Modal
          isOpen={this.state.modal}
          toggle={this.modalToggle}
          size="lg"
          className={this.props.className}
        >
          <ModalHeader toggle={this.modalToggle}>
            {this.state.modalTitle}
          </ModalHeader>
          <ModalBody>{this.state.modalBody}</ModalBody>
          <ModalFooter>
            <Button className="btn btn-accent" onClick={this.modalToggle}>
              {contentStrings.close}
            </Button>
          </ModalFooter>
        </Modal>
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
            <span className="text-uppercase page-subtitle">
              {contentStrings.certificates}
            </span>
            <h3 className="page-title">{this.props.title}</h3>
          </div>
        </div>
        <div>
          <div className="col-lg-12 col-md-12 px-0">
            <div className="col mb-4">
              <div className="mb-3">
                <h6 className="mt-0">
                  {contentStrings.coffeeBatchInformation}
                </h6>
              </div>
              <DataTable id="coffeeBatch" columns={columns} rows={rows} />
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="mb-3">
              <h6 className="mt-0">{contentStrings.assignCertificate}</h6>
            </div>
            <div className="card card-small mb-3">
              <div className="card-body">
                <form className="add-new-post" onSubmit={this.onFormSubmit}>
                  <select
                    id="inputState"
                    className="form-control  mb-3"
                    onChange={this.onChangeSelectedCertificate}
                    value={this.state.selectedCertificate}
                  >
                    <option value="0">
                      {contentStrings.selectCertificate}
                    </option>
                    {this.state.certificates.map(function(certificate) {
                      return (
                        <option value={certificate.id} key={certificate.id}>
                          {certificate.name}
                        </option>
                      );
                    })}
                  </select>
                  <button className="btn btn-accent ml-auto ">
                    <i className="fas fa-award" />
                    <span className="ml-1"> {this.props.title}</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12" />
        </div>
      </>
    );
  }
}

export default withRouter(AssignCertificate);

import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

//TODO: This component should be a single button
//TODO: change on click
class LinkButton extends Component {

  render() {
    return (
      <div
       className="btn-group btn-group-sm"
       role="group"
       aria-label="Table row actions"
      >
          <Link to={this.props.url} className="">
            <button type="button" className="btn btn-accent form-control  ">
              <i className={this.props.iconClass} /> {this.props.caption}
            </button>
          </Link>
      </div>
    );
  }
}

export default withRouter(LinkButton);
import gql from "graphql-tag";


const coffeeBatchByID = gql`
  query coffeeBatches ($coffeeBatchId: ID!) {
    coffeeBatch(id: $coffeeBatchId){
      id
      altitude
      variety
      process
      coffeeState
      size
      farm {
      	id
        name
        country
        story
        owner{
          id
        }
      }
    }
  }
`

const coffeeBatchAllByID = gql`
  query coffeeBatches ($coffeeBatchId: ID!) {
    coffeeBatch(id: $coffeeBatchId){
      id
      altitude
      variety
      process
      coffeeState
      size
      farm {
      	id
        name
        country
        region
        village
        story
        owner{
          id
        }
      }
      cupProfiles {
        id
        profile
        imageHash
        cuppingNote
        taster {
          id
        }
      }
      certificates{
        id
        name
        description
        additionalInformation
        imageHash
        certifier{
          id
        }
      }
    }
  }
`


const coffeeBatchesByProducer = gql`
  query producer ($actorAccount: ID!) {
    producer(id: $actorAccount ){
        id
    	coffeeBatches{
          id
          altitude
          process
          variety
          coffeeState
          size
          farm {
            id
            name
          }
        }
    }
  }
`


const cooperativeProducersCoffeeBatches = gql`
  query cooperative ($actorAccount: ID!) {
    cooperative(id: $actorAccount){
        id
        producers{
          id
          coffeeBatches{
            id
            altitude
            process
            variety
            coffeeState
            size
            farm{
                id
                name
                owner {
                    id
                }
            }
          }
        }
    }
  }
`

const tasterCoffeeBatches = gql`
    query taster ($actorId: ID!) {
        taster(id: $actorId){
          id
          producers {
            id
            coffeeBatches{
              id
              altitude
              process
              variety
              coffeeState
              size
              farm{
                id
                name
              }
              cupProfiles {
                id
                taster{
                  id
                }
              }
            }
          }
        }
    }
`



export {
    coffeeBatchByID,
    coffeeBatchAllByID,
    coffeeBatchesByProducer,
    cooperativeProducersCoffeeBatches,
    tasterCoffeeBatches
}
import React, { Component } from "react";
import { withRouter } from "react-router";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Loading from "./Loading";
import axios from "axios";
import constants from "../constants";
import contentStrings from "../constants/localization";
import CheckAccountPermissions from "./CheckAccountPermissions";
import {
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";
import qs from "qs";

import { useQuery } from "@apollo/react-hooks";
import { farmByID } from "./queries/Farm"

function UpdateFarm({ farmId, setStates, renderForm }){
    const { loading, error, data } = useQuery(farmByID, {
        variables: { farmId: farmId },
        onCompleted: setStates
    });

    if (loading) return <Loading />;
    if (error) return <h2>ERROR</h2>;

    return renderForm();
}


class NewFarm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country: "",
      region: "",
      farmName: "",
      story: "",
      village: "",
      status: "initialized",
      modal: false,
      transactionHash: "",
      modalSuccess: true,
      modalPending: true,
      modalBody: "",
      modalTitle: ""
    };

    this.contracts = props.drizzle.contracts;
    this.drizzle = props.drizzle;
    this.web3 = props.drizzle.web3;

    this.setStates = this.setStates.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFarmNameChange = this.onFarmNameChange.bind(this);
    this.onStoryChange = this.onStoryChange.bind(this);
    this.onVillageChange = this.onVillageChange.bind(this);
    this.modalToggle = this.modalToggle.bind(this);
  }

  modalToggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  componentDidMount() {
    const { drizzle } = this.props;
    // subscribe to changes in the store
    this.unsubscribe = drizzle.store.subscribe(() => {
      // every time the store updates, grab the state from drizzle
      const drizzleState = drizzle.store.getState();

      // check to see if it's ready, if so, update local component state
      if (drizzleState.drizzleStatus.initialized) {
        if (drizzleState.transactionStack[this.state.transactionId]) {
          const transactionHash =
            drizzleState.transactionStack[this.state.transactionId];
          if (
            drizzleState.transactions[transactionHash].status == "pending" &&
            this.state.modalPending
          ) {
            this.setState({
              transactionHash: transactionHash,
              modal: true,
              modalTitle: contentStrings.modalSubmitedTitle,
              modalBody: contentStrings.modalSubmitedText,
              modalPending: false
            });
          }
          if (
            drizzleState.transactions[transactionHash].status == "success" &&
            this.state.modalSuccess
          ) {
            if (this.props.isUpdate != null) {
              this.props.history.push("/dashboard/farms");
            }
            else {
              const id =
                drizzleState.transactions[transactionHash].receipt.events
                  .LogAddFarm.returnValues._id;

              this.setState({
                transactionHash: transactionHash,
                modal: true,
                modalTitle: contentStrings.modalSuccessTitle,
                modalBody: `${contentStrings.modalSuccessText} ${
                  this.state.transactionHash
                }`,
                modalSuccess: false
              });
              this.props.history.push("/dashboard/farms");
            }
          }
        }
      }
    });

    this.setState({ status: "complete" });
  }

  onFarmNameChange(event) {
    this.setState({ farmName: event.target.value });
  }

  selectCountry(val) {
    this.setState({ country: val });
  }

  selectRegion(val) {
    this.setState({ region: val });
  }

  onStoryChange(event) {
    this.setState({ story: event.target.value });
  }

  onVillageChange(event) {
    this.setState({ village: event.target.value });
  }

  onFormSubmit(event) {
    event.preventDefault();
    if (this.props.isUpdate != null) {
      const stackId = this.contracts.FarmFactory.methods.updateFarm.cacheSend(
        this.props.match.params.id,
        this.web3.utils.utf8ToHex(this.state.farmName),
        this.web3.utils.utf8ToHex(this.state.country),
        this.web3.utils.utf8ToHex(this.state.region),
        this.web3.utils.utf8ToHex(this.state.village),
        this.state.story,
        { from: this.props.drizzleState.accounts[0] }
      );
      this.setState({ transactionId: stackId });
    } else {
      const stackId = this.contracts.FarmFactory.methods.addFarm.cacheSend(
        this.web3.utils.utf8ToHex(this.state.farmName),
        this.web3.utils.utf8ToHex(this.state.country),
        this.web3.utils.utf8ToHex(this.state.region),
        this.web3.utils.utf8ToHex(this.state.village),
        this.state.story,
        { from: this.props.drizzleState.accounts[0] }
      );
      this.setState({ transactionId: stackId });
    }
  }

  setStates(data){
    this.setState({
        farmName: data.farm.name,
        country: data.farm.country,
        region: data.farm.region,
        village: data.farm.village,
        story: data.farm.story
    });
  }

  renderForm(){
    return (
        <div>
            <div className="form-group">
                <Label for="">{contentStrings.farmName}</Label>
                <input
                  type="text"
                  className="form-control"
                  id=""
                  placeholder={contentStrings.farmName}
                  value={this.state.farmName}
                  onChange={this.onFarmNameChange}
                />
            </div>
            <Label for="">{contentStrings.countryColumn}</Label>
            <div className="form-group">
                <CountryDropdown
                  value={this.state.country}
                  classes="form-control"
                  onChange={val => this.selectCountry(val)}
                />
            </div>
            <div className="form-group">
                <Label for="">{contentStrings.regionColumn}</Label>
                <RegionDropdown
                  classes="form-control"
                  country={this.state.country}
                  value={this.state.region}
                  onChange={val => this.selectRegion(val)}
                />
            </div>
            <div className="form-group">
                <Label for="">{contentStrings.village}</Label>
                <input
                  type="text"
                  className="form-control"
                  id=""
                  placeholder={contentStrings.village}
                  onChange={this.onVillageChange}
                  value={this.state.village}
                />
            </div>
            <div className="form-group">
                <Label for="">{contentStrings.farmStory}</Label>
                <textarea
                  className="form-control "
                  placeholder={contentStrings.farmStoryPlaceholder}
                  onChange={this.onStoryChange}
                  value={this.state.story}
                />
            </div>
        </div>
    );
  }

  render() {
    if (this.state.farms == null && this.props.isUpdate != null) {
      switch (this.state.status) {
        case "complete":
          break;
        default:
          return <Loading />;
          break;
      }
    }
    const { country, region } = this.state;

    let farmForm
    if (this.props.isUpdate != null)
        farmForm = <UpdateFarm farmId={this.props.match.params.id} setStates={this.setStates} renderForm={this.renderForm}  />;
    else
        farmForm = this.renderForm();

    return (
      <>
        <CheckAccountPermissions />
        <Modal
          isOpen={this.state.modal}
          toggle={this.modalToggle}
          size="lg"
          className={this.props.className}
        >
          <ModalHeader toggle={this.modalToggle}>
            {this.state.modalTitle}
          </ModalHeader>
          <ModalBody>{this.state.modalBody}</ModalBody>
          <ModalFooter>
            <Button className="btn btn-accent" onClick={this.modalToggle}>
              {contentStrings.close}
            </Button>
          </ModalFooter>
        </Modal>
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
            <span className="text-uppercase page-subtitle">
              {contentStrings.farms}
            </span>
            <h3 className="page-title">{this.props.title}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card card-small mb-3">
              <div className="card-body">
                <form className="add-new-post" onSubmit={this.onFormSubmit}>

                  {farmForm}

                  <button className="btn btn-accent ml-auto ">
                    <i className="fas fa-apple-alt fa-sidebar" />
                    <span className="ml-1"> {this.props.title}</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12" />
        </div>
      </>
    );
  }

}

export default withRouter(NewFarm);

import LocalizedStrings from "react-localization";

let contentStrings = new LocalizedStrings({
  en: {
    locale: "en",
    loginTitle: "Access Your Account",
    loginLabel: "Account address",
    loginButton: "Access Account",
    registerLink: "Need an Account? Create Account",
    loginErrorMessage: "There is no account associated for this user",
    accountCreatedMessage: "Account created successfully!",
    signOutMessage: "Sign Out successfully!",
    avatarColumn: "Avatar",
    nameColumn: "Full Name",
    emailColumn: "Email",
    countryColumn: "Country",
    regionColumn: "Region",
    typeColumn: "Type",
    imageColumn: "Image",
    descriptionColumn: "Description",
    actionsColumn: "Actions",
    overviewTitle: "Overview",
    permissionsTitle: "Permissions",
    addPermissionsTitle: "Add Permissions",
    permissionsTooltip:
      "Users with permissions will be able to Taste, Certify or Add value chain actions to your coffee batches",
    userAddressPlaceholder: "User Address",
    permissionsButton: "Give Permission",
    permissionsResume: "Resume of Permissions",
    qrCode: "QR Code",
    farm: "Farm",
    variety: "Variety",
    altitude: "Altitude",
    process: "Process",
    batchSize: "Batch Size",
    certificates: "Certificates",
    certificate: "Certificate",
    coffeeBatchInformation: "Coffee Batch Information",
    assignCertificate: "Assign Certificate",
    selectCertificate: "Select Certificate",
    newCertificate: "New Certificate",
    resumeCertificates: "Resume of Certificates",
    coffeeBatch404: "404: Coffee Batch Not Found",
    details: "Details",
    coffeeBatch: "Coffee Batch",
    home: "Home",
    services: "Services",
    about: "About",
    products: "Products",
    blog: "Blog",
    companyName: "Affogato",
    coffeeBatchTitle: "Coffee Batches",
    newCoffeeBatch: "New Coffee Batch",
    resumeCoffeeBatches: "Resume of Coffee Batches",
    copy: "Copy",
    copyMessage: "Successfully copied to the clipboard",
    download: "Download",
    close: "Close",
    farmer: "Farmer",
    cupProfile: "Cup Profile",
    MAMSL: "MAMSL",
    createdDate: "Created Date",
    farmStory: "Farm Story",
    farmName: "Farm Name",
    coffeeBatchIdentifier: "Coffee Batch Identifier",
    updateFarm: "Update Farm",
    createFarm: "Create Farm",
    updateCoffeeBatch: "Update Coffee Batch",
    createCupProfile: "Create Cup Profile",
    createCoffeeBatch: "Create Coffee Batch",
    createCertificate: "Create Certificate",
    settings: "Settings",
    emptyDashboardMessage1: "There are no",
    emptyDashboardMessage2: "created, Please create a new one",
    emptyDashboardMessage2F: "created, Please create a new one",
    emptyCupProfile:
      "There are no Cup Profiles created, Request farmers to give you permission to submit one",
    emptyDashboardButton: "Create New",
    emptyDashboardButtonF: "Crear New",
    farms: "Farms",
    department: "Department",
    village: "Village",
    story: "Story",
    newFarm: "New Farm",
    resumeFarm: "Resume of Farms",
    noNotificationsTitle: "No New Notifications",
    noNotificationsMessage:
      "Hooray! You have read all your notifications. Great job!",
    viewNotifications: "View all notifications",
    profile: "Profile",
    signOut: "Sign Out",
    loading: "Loading",
    selectCertificateImage: "Select Certificate Image",
    selectProfileImage: "Select Profile Image",
    certificateName: "Certificate Name",
    coffeeBatches: "Coffee Batches",
    emptyFarmMessage:
      "There are no Farms created, please create one before creating a coffee batch",
    selectFarm: "Select Farm",
    washed: "Washed",
    natural: "Natural",
    Honey: "Honey",
    semiHoney: "Semi Honey",
    ql: "QL",
    selectCupProfileImage: "Select Cup Profile Image",
    cupProfiles: "Cup Profiles",
    newCupProfile: "New Cup Profile",
    aroma: "Aroma",
    sweetness: "Sweetness",
    flavor: "Flavor",
    acidity: "Acidity",
    body: "Body",
    aftertaste: "Aftertaste",
    cuppingNote: "Cupping Note",
    createNewAccount: "Create New Account",
    accountAddress: "Account Address",
    emailAddress: "Email Address",
    emailMessage: "Enter email",
    accountType: "Account Type",
    taster: "Taster",
    findUser: "Find User",
    certifier: "Certifier",
    gotAccount: "Got an Account? Sign In",
    dashboard: "Dashboard",
    cupProfilePreview: "Cup Profile Preview",
    web3Warning:
      "This browser has no connection to the Ethereum network. Please use the Chrome/FireFox extension MetaMask, or dedicated Ethereum browsers. Click here to download Metamask",
    wrongNetworkWarning:
      "You are using the wrong network, please connect to the Rinkeby/Ropsten Network using Metamask",
    modalSubmitedTitle: "Transaction Submited!",
    modalSubmitedText:
      "Please don't refresh or close the window. Wait until it's confirmed",
    modalSuccessTitle: "Success!",
    modalSuccessText:
      "The information was saved in the blockchain with the confirmation hash:",
    profileImage: "Profile Image",
    shortBio: "Short Biography",
    shortBioPlaceholder: "Small description about you",
    selectCountry: "Select Country",
    selectRegion: "Select Region",
    farmStoryPlaceholder:
      "Add all information that is relevant to your farm story, year of funding, past owners, etc.",
    altitudePlaceholder: "Altitude of the farm on MAMSL",
    varietyPlaceholder: "Coffee plant variety",
    selectProcess: "Select Process",
    batchSizePlaceholder: "Batch size in QL",
    cooperative: "Cooperative",
    producer: "Producer",
    producers: "Producers",
    newProducer: "New Producer",
    view: "View",
    add: "Add",
    contactInformation: "Contact Information",
    generateAddress: "Generate Address",
    updateProfile: "Update Profile",
    updatingProfile: "Updating Profile...",
    updatingProfileComplete: "Profile was updated!",
    uploadingImage: "Upload in progress, please wait...",
    uploadComplete: "Upload done!",
    tipBurner: "Tip with Burner Wallet",
    rateCoffee: "Rate this Coffee",
    cherry: "Cherry",
    wet: "Wet",
    dry: "Dry",
    green: "Green",
    roasted: "Roasted",
    coffeeStatus: "Coffee Status",
    edit: "Edit",
    createToken: "Create Token",
    coffeeAmount: "Coffee Amount",
    tokenAmount: "Amount of Tokens to Create"
  },
  es: {
    locale: "es",
    loginTitle: "Accede a tu Cuenta",
    loginLabel: "Dirección de cuenta",
    loginButton: "Acceder cuenta",
    registerLink: "¿Necesitas una cuenta? Crea una Cuenta",
    loginErrorMessage: "No hay cuenta asociada a este usuario",
    accountCreatedMessage: "¡Cuenta creada exitosamente!",
    signOutMessage: "¡Cierre de sesion exitoso!",
    avatarColumn: "Avatar",
    nameColumn: "Nombre Completo",
    emailColumn: "Correo",
    countryColumn: "País",
    regionColumn: "Región",
    typeColumn: "Tipo",
    imageColumn: "Imagen",
    descriptionColumn: "Descripcion",
    actionsColumn: "Acciones",
    overviewTitle: "Resumen",
    permissionsTitle: "Permisos",
    addPermissionsTitle: "Agregar Permisos",
    permissionsTooltip:
      "Usuarios con permisos puede Catar, Certificar o Trazar tus lotes de café",
    userAddressPlaceholder: "Dirección de Usuario",
    permissionsButton: "Dar Permiso",
    permissionsResume: "Resumen de Permisos",
    qrCode: "QR Code",
    farm: "Granja",
    variety: "Variedad",
    altitude: "Altitud",
    process: "Proceso",
    batchSize: "Tamaño de Lote",
    certificates: "Certificados",
    certificate: "Certificado",
    coffeeBatchInformation: "Información de Lotes de Café",
    assignCertificate: "Asignar Certificado",
    selectCertificate: "Seleccionar Certificado",
    newCertificate: "Certificado Nuevo",
    resumeCertificates: "Resumen de Certificados",
    coffeeBatch404: "404: Lote de café no encontrado",
    details: "Detalles",
    coffeeBatch: "Lote de Café",
    home: "Inicio",
    services: "Servicios",
    about: "Acerca de",
    products: "Productos",
    blog: "Blog",
    companyName: "Affogato",
    coffeeBatchTitle: "Lotes de Café",
    newCoffeeBatch: "Nuevo Lote de Café",
    resumeCoffeeBatches: "Resumen de Lote de Cafés",
    copy: "Copiar",
    copyMessage: "Copiado exitósamente",
    download: "Descargar",
    close: "Cerrar",
    farmer: "Productor",
    cupProfile: "Perfil de Taza",
    MAMSL: "MSNM",
    createdDate: "Fecha de Creación",
    farmStory: "Historia de Granja",
    farmName: "Nombre de Granja",
    coffeeBatchIdentifier: "Identificador de Lote",
    updateFarm: "Actualizar Granja",
    createFarm: "Crear Granja",
    updateCoffeeBatch: "Actualizar Lote de Café",
    createCupProfile: "Crear Perfil de Taza",
    createCoffeeBatch: "Crear Lote de Café",
    createCertificate: "Crear Certificado",
    settings: "Ajustes",
    emptyDashboardMessage1: "No hay",
    emptyDashboardMessage2: "creados, Por favor crea uno nuevo",
    emptyDashboardMessage2F: "creadas, Por favor crea una nueva",
    emptyDashboardButton: "Crear Nuevo",
    emptyDashboardButtonF: "Crear Nueva",
    emptyCupProfile:
      "No hay Perfil de Tazas creadas, Pide a los productores que te den permiso para ingresar una nueva",
    farms: "Granjas",
    department: "Departmento",
    village: "Municipio",
    story: "Historia",
    newFarm: "Nueva Granja",
    resumeFarm: "Resumen de Granjas",
    noNotificationsTitle: "No Hay Notificaciones Nuevas",
    noNotificationsMessage: "Excelente! Leiste todas las notificaciones!",
    viewNotifications: "Ver todas las notificaciones",
    profile: "Perfil",
    signOut: "Cerrar Sesión",
    loading: "Cargando",
    selectCertificateImage: "Seleccionar Imagen del Certificado",
    selectProfileImage: "Seleccionar Imagen de Perfil",
    certificateName: "Nombre de Certificado",
    coffeeBatches: "Lotes de Café",
    emptyFarmMessage:
      "No hay Granjas creadas, por favor crea una antes de crear un lote de café",
    selectFarm: "Seleccionar Granja",
    washed: "Lavado",
    natural: "Natural",
    Honey: "Melado",
    semiHoney: "Semi Melado",
    ql: "QQ",
    selectCupProfileImage: "Seleccionar Imagen de Catación",
    cupProfiles: "Perfil de Tazas",
    newCupProfile: "Nuevo Perfil de Taza",
    aroma: "Aroma",
    sweetness: "Dulzura",
    flavor: "Sabor",
    acidity: "Acidez",
    body: "Cuerpo",
    aftertaste: "Postgusto",
    cuppingNote: "Nota de Catación",
    createNewAccount: "Crear Nueva Cuenta",
    accountAddress: "Dirección de Cuenta",
    emailAddress: "Dirección de Correo",
    emailMessage: "Ingresa un correo",
    accountType: "Tipo de Cuenta",
    taster: "Catador",
    findUser: "Buscar Usuario",
    certifier: "Certificador",
    gotAccount: " Tienes cuenta? Logueate",
    dashboard: "Panel",
    cupProfilePreview: "Vista Previa de Perfil de Taza",
    web3Warning:
      "Este navegador no cuenta con conexión a la red de Ethereum. Por favor usa la extensión de Metamask para Chrome/FireFox o un navegador especializado. Haz click acá para descargar Metamask",
    wrongNetworkWarning:
      "Estas utilizando la red incorrecta, por favor cambia la red a Rinkeby/Ropsten usando Metamask",
    modalSubmitedTitle: "Transacción ingresada!",
    modalSubmitedText:
      "Por favor no recargues el sitio web o cierres la ventana. Espera a que sea confirmada",
    modalSuccessTitle: "Éxito!",
    modalSuccessText:
      "La información fue guardada en el blockchain con el identificador:",
    profileImage: "Imagen de Perfil",
    shortBio: "Biografía corta",
    shortBioPlaceholder: "Corta descripción acerca de ti",
    selectCountry: "Selecciona el País",
    selectRegion: "Selecciona la Región",
    farmStoryPlaceholder:
      "Ingresa la historia de tu granja. En que año se creo, sus pasados dueños, todo lo que sea relevante.",
    altitudePlaceholder: "Altitud de la granja en MSNM",
    varietyPlaceholder: "Variedad de la planta de café",
    selectProcess: "Selecciona el Proceso",
    batchSizePlaceholder: "Tamaño del lote en quintales",
    cooperative: "Cooperativa",
    producer: "Productor",
    producers: "Productores",
    newProducer: "Nuevo Productor",
    view: "Ver",
    add: "Agregar",
    contactInformation: "Información de Contacto",
    generateAddress: "Generar Dirección",
    updateProfile: "Actualizar Cuenta",
    updatingProfile: "Actualizando cuenta...",
    updatingProfileComplete: "Cuenta fue actualizada!",
    uploadingImage: "Carga en curso, por favor espere...",
    uploadComplete: "Carga completada!",
    tipBurner: "Enviar Propina con Burner Wallet",
    rateCoffee: "Califica este Café",
    cherry: "Cereza",
    wet: "Humedo",
    dry: "Pergamino Seco",
    green: "Oro Verde",
    roasted: "Tostado",
    coffeeStatus: "Estado del Café",
    edit: "Editar",
    createToken: "Crear Token",
    coffeeAmount: "Cantidad de Café",
    tokenAmount: "Cantidad de Tokens a crear"
  }
});

export default contentStrings;

import React, { Component } from "react";

class SidebarItem extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = { handleClick: props.handleClick, id: props.id };
  }

  handleClick(event) {
    event.preventDefault();
    this.state.handleClick(this.state.id);
  }

  render() {
    return (
      <li className="nav-item">
        <a
          className={`nav-link ${this.props.active}`}
          href={this.props.url}
          onClick={this.handleClick}
        >
          <i className={`fas fa-sidebar ${this.props.iconClass}`} />
          <span>{this.props.title}</span>
        </a>
      </li>
    );
  }
}

export default SidebarItem;

// src/js/actions/index.js
import { SIDEBAR_ITEM_CLICK, USER_LOGIN } from "../constants/action-types";

export const clickMenuItem = menuItem => ({
  type: SIDEBAR_ITEM_CLICK,
  payload: menuItem
});
export const setAccountType = accountType => ({
  type: USER_LOGIN,
  payload: accountType
});

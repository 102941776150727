import React, { Component } from "react";
import { withRouter } from "react-router";
import Loading from "./Loading";
import EmptyDashboardTable from "./EmptyDashboardTable";
import { connect } from "react-redux";
import { clickMenuItem } from "../actions/index";
import axios from "axios";
import constants from "../constants";
import contentStrings from "../constants/localization";
import { coffeeBatchProcesses, coffeeBatchStates } from "../constants/choices";
import CheckAccountPermissions from "./CheckAccountPermissions";
import ProducerFarmsSelect from "./ProducerFarmsSelect"
import {
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";
import qs from "qs";
import FirebaseDBService from "../services/firebaseService";

import { useQuery } from "@apollo/react-hooks";
import { producerById } from "./queries/Actor";
import { producerFarms } from "./queries/Farm"
import { coffeeBatchByID } from "./queries/CoffeeBatch";


function UpdateCoffeeBatch({ coffeeBatchId, setStates, renderForm }){
    const { loading, error, data } = useQuery(coffeeBatchByID, {
        variables: { coffeeBatchId: coffeeBatchId },
        onCompleted: setStates
    });

    if (loading) return <Loading />;
    if (error) return <h2>ERROR</h2>;

    return renderForm();
}


//TODO: remove decimals
class NewCoffeeBatch extends Component {
  constructor(props) {
    super(props);

    this.drizzle = props.drizzle;
    this.contracts = props.drizzle.contracts;
    this.web3 = props.drizzle.web3;
    this.db = new FirebaseDBService();

    this.state = {
      producerAccount: "",
      producerName: "",
      farms: null,
      status: "initialized",
      selectedFarm: "0",
      altitude: "",
      variety: "",
      process: "0",
      coffeeState: "",
      batchSize: "",
      modal: false,
      transactionHash: "",
      modalSuccess: true,
      modalPending: true,
      modalBody: "",
      modalTitle: ""
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.newFarmClick = this.newFarmClick.bind(this);
    this.onChangeSelectedFarm = this.onChangeSelectedFarm.bind(this);
    this.onChangeAltitude = this.onChangeAltitude.bind(this);
    this.onChangeVariety = this.onChangeVariety.bind(this);
    this.onChangeProcess = this.onChangeProcess.bind(this);
    this.onChangeCoffeeState = this.onChangeCoffeeState.bind(this);
    this.onChangeBatchSize = this.onChangeBatchSize.bind(this);
    this.modalToggle = this.modalToggle.bind(this);
  }

  modalToggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  componentDidMount() {
    const { drizzle } = this.props;
    // subscribe to changes in the store
    this.unsubscribe = drizzle.store.subscribe(() => {
      // every time the store updates, grab the state from drizzle
      const drizzleState = drizzle.store.getState();

      // check to see if it's ready, if so, update local component state
      if (drizzleState.drizzleStatus.initialized) {
        if (drizzleState.transactionStack[this.state.transactionId]) {
          const transactionHash =
            drizzleState.transactionStack[this.state.transactionId];
          if (
            drizzleState.transactions[transactionHash].status == "pending" &&
            this.state.modalPending
          ) {
            this.setState({
              transactionHash: transactionHash,
              modal: true,
              modalTitle: contentStrings.modalSubmitedTitle,
              modalBody: contentStrings.modalSubmitedText,
              modalPending: false
            });
          }
          if (
            drizzleState.transactions[transactionHash].status == "success" &&
            this.state.modalSuccess
          ) {
            var id = 0;
            if (this.props.isUpdate != null)
              id = this.props.match.params.id;
            else
              id = drizzleState.transactions[transactionHash].receipt.events
                        .LogCooperativeAddCoffeeBatch.returnValues._id;

            this.setState({
              transactionHash: transactionHash,
              modal: true,
              modalTitle: contentStrings.modalSuccessTitle,
              modalBody: `${contentStrings.modalSuccessText} ${
                this.state.transactionHash
              }`,
              modalSuccess: false
            });

            this.props.history.push("/dashboard/coffeeBatches");
          }
        }
      }
    });

    this.loadProducerData();
  }

  onChangeSelectedFarm(event) {
    this.setState({ selectedFarm: event.target.value });
  }
  onChangeAltitude(event) {
    this.setState({ altitude: event.target.value });
  }
  onChangeVariety(event) {
    this.setState({ variety: event.target.value });
  }
  onChangeProcess(event) {
    this.setState({ process: event.target.value });
  }
  onChangeCoffeeState(event) {
    this.setState({ coffeeState: event.target.value });
  }
  onChangeBatchSize(event) {
    this.setState({ batchSize: event.target.value });
  }

  newFarmClick() {
    this.props.setActiveItem(1);
  }

  onFormSubmit(event) {
    event.preventDefault();
    const batchSize = this.state.batchSize * 100;

    if (this.props.isUpdate != null) {
      const stackId = this.contracts.CoffeeBatchFactory.methods.cooperativeUpdateCoffeeBatch.cacheSend(
        this.props.match.params.id,
        this.state.selectedFarm,
        this.state.altitude,
        this.web3.utils.utf8ToHex(this.state.variety),
        this.web3.utils.utf8ToHex(this.state.process),
        batchSize,
        this.web3.utils.utf8ToHex(this.state.coffeeState),
        "",
        this.state.producerAccount,
        { from: this.props.drizzleState.accounts[0] }
      );
      this.setState({ transactionId: stackId });
    } else {
      const stackId = this.contracts.CoffeeBatchFactory.methods.cooperativeAddCoffeeBatch.cacheSend(
        this.state.selectedFarm,
        this.state.altitude,
        this.web3.utils.utf8ToHex(this.state.variety),
        this.web3.utils.utf8ToHex(this.state.process),
        batchSize,
        this.web3.utils.utf8ToHex(this.state.coffeeState),
        "",
        this.state.producerAccount,
        { from: this.props.drizzleState.accounts[0] }
      );
      this.setState({ transactionId: stackId });
    }
  }

  setStates(data){
    let coffeeBatch = data.coffeeBatch;
    const batchSize = coffeeBatch.size/100;

    if (this.props.isUpdate != null){
        this.setState({
            producerAddress: coffeeBatch.farm.owner.id,
            selectedFarm: coffeeBatch.farm.id,
            altitude: coffeeBatch.altitude,
            variety: coffeeBatch.variety,
            process: coffeeBatch.process,
            coffeeState: coffeeBatch.coffeeState,
            batchSize: batchSize,
        });
    }
    else{
        this.setState({
            producerAddress: data.id,
        });
    }
  }

  async loadProducerData(){
    const account = this.props.match.params.id;
    const actor = await this.db.getAccount(account);


    if (actor.error) {
      console.error(`Error retrieving account: ${account}`);
    }
    this.setState({
      producerAccount: account.toLowerCase(),
      producerName: actor.name,
      status: "complete"
    });
  }


  renderForm(){
    return (<div>
              <div className="form-group">
                <Label for="">
                  {contentStrings.producer} {contentStrings.name}
                </Label>
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={this.state.producerName}
                />
              </div>
              <ProducerFarmsSelect producerAccount={this.state.producerAccount} onChangeSelectedFarm={this.onChangeSelectedFarm} selectedFarm={this.state.selectedFarm} />
              <div className="form-group">
                <Label for="">{contentStrings.altitude}</Label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control "
                    placeholder={contentStrings.altitudePlaceholder}
                    aria-label=""
                    aria-describedby="basic-addon2"
                    onChange={this.onChangeAltitude}
                    value={this.state.altitude}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      {contentStrings.MAMSL}
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <Label for="">{contentStrings.variety}</Label>
                <input
                  className="form-control  mb-3"
                  type="text"
                  placeholder={contentStrings.varietyPlaceholder}
                  onChange={this.onChangeVariety}
                  value={this.state.variety}
                />
              </div>
              <div className="form-group">
                <Label for="">{contentStrings.selectProcess}</Label>
                <select
                  id="inputState"
                  className="form-control mb-3"
                  onChange={this.onChangeProcess}
                  value={this.state.process}
                >
                  <option value="0">{contentStrings.process}</option>
                  {Object.keys(coffeeBatchProcesses).map(function(
                    processKey
                  ) {
                    return (
                      <option value={processKey}>
                        {coffeeBatchProcesses[processKey]}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-group">
                <Label for="">{contentStrings.coffeeStatus}</Label>
                <select
                  id="inputState"
                  className="form-control mb-3"
                  onChange={this.onChangeCoffeeState}
                  value={this.state.coffeeState}
                >
                  <option value="0">{contentStrings.coffeeState}</option>
                  {Object.keys(coffeeBatchStates).map(function(
                    coffeeStateKey
                  ) {
                    return (
                      <option value={coffeeStateKey}>
                        {coffeeBatchStates[coffeeStateKey]}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-group">
                <Label for="">{contentStrings.batchSize}</Label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control "
                    placeholder={contentStrings.batchSizePlaceholder}
                    aria-label=""
                    aria-describedby="basic-addon2"
                    onChange={this.onChangeBatchSize}
                    value={this.state.batchSize}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      {contentStrings.ql}
                    </span>
                  </div>
                </div>
              </div>
            </div>);

  }

  render() {
    let coffeeBatchForm

    if (this.state.producerAccount == "") {
      switch (this.state.status) {
        case "complete":
          break;
        default:
          return <Loading />;
          break;
      }
    }

    if (this.props.isUpdate != null)
        coffeeBatchForm = <UpdateCoffeeBatch coffeeBatchId={this.props.match.params.id} setStates={this.setStates} renderForm={this.renderForm}  />;
    else
        coffeeBatchForm = this.renderForm();

    return (
      <>
        <CheckAccountPermissions />
        <Modal
          isOpen={this.state.modal}
          toggle={this.modalToggle}
          size="lg"
          className={this.props.className}
        >
          <ModalHeader toggle={this.modalToggle}>
            {this.state.modalTitle}
          </ModalHeader>
          <ModalBody>{this.state.modalBody}</ModalBody>
          <ModalFooter>
            <Button className="btn btn-accent" onClick={this.modalToggle}>
              {contentStrings.close}
            </Button>
          </ModalFooter>
        </Modal>
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
            <span className="text-uppercase page-subtitle">
              {contentStrings.coffeeBatches}
            </span>
            <h3 className="page-title">{this.props.title}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card card-small mb-3">
              <div className="card-body">
                <form className="add-new-post" onSubmit={this.onFormSubmit}>
                  {coffeeBatchForm}

                  <button className="btn btn-accent ml-auto ">
                    <i className="fas fa-seedling fa-sidebar" />
                    <span className="ml-1">{this.props.title}</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12" />
        </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setActiveItem: activeMenuItem => dispatch(clickMenuItem(activeMenuItem))
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(NewCoffeeBatch)
);

import React, { Component } from "react";
import { withRouter } from "react-router";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Loading from "./Loading";
import constants from "../constants";
import contentStrings from "../constants/localization";
import Blockies from "react-blockies";
import {
  FormGroup,
  Label,
  Input,
  FormText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";
import qs from "qs";
import ipfs from "../scripts/ipfs";
import { toast } from "react-toastify";
import { css } from "glamor";
import FirebaseDBService from "../services/firebaseService";

//TODO: show toastify when created or updated
//TODO: Refactor name to Farm Form.
//TODO: Change url to constant
//TODO: url change depending on enviroment

class UpdateProfile extends Component {
  constructor(props) {
    super(props);
    this.db = new FirebaseDBService();
    this.state = {
      country: "",
      region: "",
      name: "",
      email: "",
      accountAddress: this.props.drizzleState.accounts[0].toLowerCase(),
      accountType: constants.ROLES.FARMER,
      profileImageText: contentStrings.selectProfileImage,
      imageHash: "",
      bio: "",
      buffer: "",
      imageLoading: false,
      redirect: false,
      modal: false,
      transactionHash: "",
      modalSuccess: true,
      modalPending: true,
      modalBody: "",
      modalTitle: ""
    };

    this.contracts = props.drizzle.contracts;
    this.drizzle = props.drizzle;
    this.web3 = props.drizzle.web3;

    this.onChangeName = this.onChangeName.bind(this);
    this.onSelectAccountType = this.onSelectAccountType.bind(this);
    this.onSelectCountry = this.onSelectCountry.bind(this);
    this.onSelectRegion = this.onSelectRegion.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeBio = this.onChangeBio.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.modalToggle = this.modalToggle.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
  }

  modalToggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  onChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  onChangeName(event) {
    this.setState({ name: event.target.value });
  }

  onChangeBio(event) {
    this.setState({ bio: event.target.value });
  }

  onSelectAccountType(event) {
    this.setState({ accountType: event.target.value });
  }

  onSelectCountry(value) {
    this.setState({ country: value });
  }

  onSelectRegion(value) {
    this.setState({ region: value });
  }

  async componentDidMount() {
    const actor = await this.db.getAccount(this.state.accountAddress);
    if (actor.error) {
      console.error(`Error retrieving account: ${this.state.accountAddress}`);
    }

    console.log('ENCONTRO Actor');
    this.setState({
      address: actor.address,
      country: actor.country,
      region: actor.region,
      name: actor.name,
      email: actor.email,
      accountType: actor.typeOfActor,
      imageHash: actor.image_hash,
      bio: actor.bio,
      status: "complete"
    });
  }

  selectCountry(val) {
    this.setState({ country: val });
  }

  selectRegion(val) {
    this.setState({ region: val });
  }

  //Take file input from user
  //TODO: restrict only images
  captureFile = event => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ imageLoading: true });
    var profileImageText = contentStrings.selectProfileImage;
    if (event.target.files[0] != null) {
      profileImageText = event.target.files[0].name;
    }

    // this.setState({ profileImageText: "Loading..." });
    const file = event.target.files[0];
    if (file) {
      let reader = new window.FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = () => this.convertToBuffer(reader, profileImageText);
    } else {
      this.setState({
        profileImageText: contentStrings.selectProfileImage,
        buffer: "",
        imageLoading: false
      });
    }
  };

  //Convert the file to buffer to store on IPFS
  convertToBuffer = async (reader, profileImageText) => {
    //file is converted to a buffer for upload to IPFS
    this.setState({ profileImageText });
    const buffer = await Buffer.from(reader.result);
    //set this buffer-using es6 syntax
    this.setState({ buffer });
  };

  onFormSubmit = async event => {
    event.preventDefault();
    if (this.state.imageLoading) {
      let toastId = toast(`🏞️ ${contentStrings.uploadingImage}`, {
        position: "bottom-right",
        autoClose: false
      });
      await ipfs.add(this.state.buffer, (err, ipfsHash) => {
        toast.update(toastId, {
          render: `☕ ${contentStrings.uploadComplete}`,
          autoClose: true,
          autoClose: 1000,
          progressClassName: css({
            background: "linear-gradient(90deg, #332211, #cc7722, #774411)"
          })
        });
        this.setState({ imageHash: ipfsHash[0].hash });
        this.updateProfile();
      });
    } else {
      this.updateProfile();
    }
  };

  async updateProfile() {
    let toastId = toast(`🏞️ ${contentStrings.updatingProfile}`, {
      position: "top-right",
      autoClose: false
    });
    if (this.state.country !== "" && this.state.region !== "" && this.state.email !== "") {
      const address = this.state.accountAddress;
      const data = {
        name: this.state.name,
        typeOfActor: this.state.accountType,
        country: this.state.country,
        region: this.state.region,
        email: this.state.email,
        image_hash: this.state.imageHash,
        bio: this.state.bio
      };
      const response = await this.db.updateAccount(address, { ...data, address });
      if(!response.error){
        toast.update(toastId, {
          render: `☕ ${contentStrings.updatingProfileComplete}`,
          autoClose: 1000,
          progressClassName: css({
            background: "linear-gradient(90deg, #332211, #cc7722, #774411)"
          })
        });     
      }
    }
  }

  render() {
    const { country, region } = this.state;
    return (
      <>
        <Modal
          isOpen={this.state.modal}
          toggle={this.modalToggle}
          size="lg"
          className={this.props.className}
        >
          <ModalHeader toggle={this.modalToggle}>
            {this.state.modalTitle}
          </ModalHeader>
          <ModalBody>{this.state.modalBody}</ModalBody>
          <ModalFooter>
            <Button className="btn btn-accent" onClick={this.modalToggle}>
              {contentStrings.close}
            </Button>
          </ModalFooter>
        </Modal>
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
            <span className="text-uppercase page-subtitle">
              {contentStrings.profile}
            </span>
            <h3 className="page-title">{this.props.title}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card card-small mb-3">
              <div className="card-body">
                <form className="add-new-post" onSubmit={this.onFormSubmit}>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.accountAddress}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.accountAddress}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.emailAddress}s</label>
                    <input
                      type="email"
                      className="form-control"
                      id=""
                      aria-describedby=""
                      placeholder={contentStrings.emailMessage}
                      onChange={this.onChangeEmail}
                      value={this.state.email}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.nameColumn}</label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder={contentStrings.nameColumn}
                      onChange={this.onChangeName}
                      value={this.state.name}
                    />
                  </div>
                  <div className="form-group">
                    <Label for="">{contentStrings.profileImage}</Label>
                    <br />
                    {this.state.imageHash != "" ? (
                      <img
                        src={`${constants.IPFS_URL}/${this.state.imageHash}`}
                        width="40"
                        className="user-avatar rounded-circle mr-2"
                      />
                    ) : (
                        <Blockies
                          seed={this.props.drizzleState.accounts[0]}
                          size={4}
                          scale={10}
                          className="identicon user-avatar rounded-circle mr-2"
                        />
                      )}
                    <Input
                      type="file"
                      className="mt-2"
                      onChange={this.captureFile}
                    />
                    <FormText color="muted">
                      {this.state.profileImageText}
                    </FormText>
                  </div>
                  <FormGroup>
                    <Label for="">{contentStrings.shortBio}</Label>
                    <Input
                      type="textarea"
                      placeholder={contentStrings.shortBioPlaceholder}
                      onChange={this.onChangeBio}
                      value={this.state.bio}
                    />
                  </FormGroup>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.accountType}</label>
                    <select
                      className="form-control"
                      value={this.state.accountType}
                      onChange={this.onSelectAccountType}
                    >
                      <option value="farmer">{contentStrings.farmer}</option>
                      <option value="taster">{contentStrings.taster}</option>
                      <option value="cooperative">
                        {contentStrings.cooperative}
                      </option>
                      <option value="certifier">
                        {contentStrings.certifier}
                      </option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.countryColumn}</label>
                    <CountryDropdown
                      value={country}
                      classes="form-control"
                      onChange={this.onSelectCountry}
                      defaultOptionLabel={contentStrings.selectCountry}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">{contentStrings.regionColumn}</label>
                    <RegionDropdown
                      classes="form-control"
                      country={country}
                      value={region}
                      onChange={this.onSelectRegion}
                      defaultOptionLabel={contentStrings.selectRegion}
                    />
                  </div>
                  <button className="btn btn-accent ml-auto ">
                    <i className="fas fa-user-alt fa-sidebar" />
                    <span className="ml-1"> {this.props.title}</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12" />
        </div>
      </>
    );
  }
}

export default withRouter(UpdateProfile);

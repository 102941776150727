import React, { Component } from "react";
const $ = require("jquery");
$.DataTable = require("datatables.net");

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = { columns: props.columns };
  }

  componentDidMount() {
    $(`#${this.props.id}`).DataTable();
  }

  componentWillUnmount() {
    $(`#${this.props.id}`)
      .DataTable()
      .destroy(true);
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <table id={this.props.id} className="transaction-history">
        <thead>
          <tr>
            {this.props.columns.map(function(value, index) {
              return (
                <th scope="col" className="border-0" key={index}>
                  {value}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {this.props.rows.map(function(data, index) {
            return (
              <tr key={index}>
                {data.map(function(result, index) {
                  return <td key={index}>{result}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default DataTable;

import React, { Component } from "react";
import DataTable from "./DataTable";
import DataTableButton from "./DataTableButton";
import FindActorSelect from "./FindActorSelect";

import { withRouter } from "react-router";
import Loading from "./Loading";
import _ from "lodash";
import axios from "axios";
import "./Alloweds.css";
import ReactTooltip from "react-tooltip";
import Gravatar from "react-gravatar";
import constants from "../constants";
import contentStrings from "../constants/localization";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import qs from "qs";
import Blockies from "react-blockies";
import { useQuery } from "@apollo/react-hooks";
import { producerAlloweds } from "./queries/Actor";
import FirebaseDBService from "../services/firebaseService";


function LoadAlloweds({ accountAddress }){
    const db = new FirebaseDBService();
    const columns = [
      contentStrings.avatarColumn,
      contentStrings.nameColumn,
      contentStrings.emailColumn,
      contentStrings.countryColumn,
      contentStrings.regionColumn,
      contentStrings.typeColumn,
      contentStrings.actionsColumn
    ];
    const rows = [];
    var currentActor = [];
    var allowedAddresses = [];

    const { loading, error, data } = useQuery(producerAlloweds, {
        variables: { producerAccount: accountAddress },
    });

    if (loading) return <Loading />;
    if (error) return <h2>ERROR</h2>;

    let producer = data.producer;
    if (producer != null){
        if (producer.allowedCooperatives != null){
            producer.allowedCooperatives.map(cooperative => (
                allowedAddresses.push(cooperative.id)
            ));
        }
        if (producer.allowedTasters != null){
            producer.allowedTasters.map(taster => (
                allowedAddresses.push(taster.id)
            ));
        }

    }
}

class Alloweds extends Component {
  constructor(props) {
    super(props);

    this.drizzle = props.drizzle;
    this.contracts = props.drizzle.contracts;
    this.web3 = props.drizzle.web3;
    this.db = new FirebaseDBService();

    this.state = {
      actors: null,
      allowedAddress: "",
      currentAlloweds: "",
      allowedsInfo: [],
      status: "initialized",
      removingPermission: false,
      modal: false,
      transactionHash: "",
      modalSuccess: true,
      modalPending: true,
      modalBody: "",
      modalTitle: ""
    };

    this.loadActorAllowedsInfo = this.loadActorAllowedsInfo.bind(this);
    this.updateActorAllowers = this.updateActorAllowers.bind(this);
    this.deleteActorAllowers = this.deleteActorAllowers.bind(this);
    this.deleteActorAlloweds = this.deleteActorAlloweds.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onTrashAllowed = this.onTrashAllowed.bind(this);
    this.onSelectedActorChange = this.onSelectedActorChange.bind(this);
    this.modalToggle = this.modalToggle.bind(this);
  }

  modalToggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  componentDidMount() {
    this.getUserData();
    const { drizzle } = this.props;
    // subscribe to changes in the store
    this.unsubscribe = drizzle.store.subscribe(() => {
      // every time the store updates, grab the state from drizzle
      const drizzleState = drizzle.store.getState();

      // check to see if it's ready, if so, update local component state
      if (drizzleState.drizzleStatus.initialized) {
        if (drizzleState.transactionStack[this.state.transactionId]) {
          const transactionHash =
            drizzleState.transactionStack[this.state.transactionId];
          if (
            drizzleState.transactions[transactionHash].status == "pending" &&
            this.state.modalPending
          ) {
            this.setState({
              transactionHash: transactionHash,
              modal: true,
              modalTitle: contentStrings.modalSubmitedTitle,
              modalBody: contentStrings.modalSubmitedText,
              modalPending: false
            });
          }

          if (
            drizzleState.transactions[transactionHash].status == "success" &&
            this.state.modalSuccess
          ) {
            const owner =
              drizzleState.transactions[transactionHash].receipt.events
                .LogApproval.returnValues._owner;
            const allowed =
              drizzleState.transactions[transactionHash].receipt.events
                .LogApproval.returnValues._allowed;
            const isAllowed =
              drizzleState.transactions[transactionHash].receipt.events
                .LogApproval.returnValues._value;

            this.setState({
              transactionHash: transactionHash,
              modal: true,
              modalTitle: contentStrings.modalSuccessTitle,
              modalBody: `${contentStrings.modalSuccessText} ${
                this.state.transactionHash
              }`,
              modalSuccess: false
            });

            if (!this.state.removingPermission){
                const address = this.props.drizzleState.accounts[0].toLowerCase();
                const newAlloweds = this.state.currentAlloweds;
                newAlloweds.push(this.state.allowedAddress.toLowerCase());

                const data = {
                    alloweds: newAlloweds
                };
                const response = this.db.updateAccount(address, { ...data, address });

                this.updateActorAllowers(this.state.allowedAddress.toLowerCase());
                this.setState({ allowedAddress: "" });
            }
            else
                this.setState({
                  removingPermission: false
                });

            this.getUserData();
          }
        }
      }
    });
  }

  async updateActorAllowers(address){
    const allowers = [];
    const allowerAccount = this.props.drizzleState.accounts[0].toLowerCase();
    const actor = await this.db.getAccount(address);

    if (!actor.error){
        if (actor.allowers != null){
           actor.allowers.map(allower => (
                allowers.push(allower)
           ));
        }
        allowers.push(allowerAccount);
        const data = {
            allowers: allowers
        };
        const response = this.db.updateAccount(address, { ...data, address });
    }
    else{
        console.error(`Error retrieving account: ${address}`);
    }
  }

  async loadActorAllowedsInfo() {
    const alloweds = [], allowedsInfo = [];
    const actors = await this.db.getActorAlloweds(
      this.props.drizzleState.accounts[0].toLowerCase()
    );
    actors.map(actor => (
      alloweds.push(actor.address),
      allowedsInfo.push({
        id: actor.address,
        address: actor.address,
        email: actor.email,
        image_hash: actor.image_hash,
        name: actor.name,
        country: actor.country,
        region: actor.region
      })
    ));

    this.setState({
        status: "completed",
        currentAlloweds: alloweds,
        allowedsInfo: allowedsInfo
    });
  }

  async deleteActorAllowers(address){
    const allowerAccount = this.props.drizzleState.accounts[0].toLowerCase();
    const actor = await this.db.getAccount(address);

    if (!actor.error){
        if (actor.allowers != null){
           let allowers = actor.allowers.filter(item => item !== allowerAccount);
           const data = {
                allowers: allowers
           };
           const response = this.db.updateAccount(address, { ...data, address });
        }
    }
    else{
        console.error(`Error retrieving account: ${address}`);
    }

  }

  async deleteActorAlloweds(address, allowedAddress){
    let newAlloweds = this.state.currentAlloweds.filter(item => item !== allowedAddress);
    const data = {
        alloweds: newAlloweds
    };
    const response = this.db.updateAccount(address, { ...data, address });
  }

  onTrashAllowed(address) {
    const stackId = this.contracts.ActorFactory.methods.approve.cacheSend(
      address,
      false,
      { from: this.props.drizzleState.accounts[0] }
    );

    this.setState({
      transactionId: stackId,
      removingPermission: true,
      modalSuccess: true,
      modalPending: true
    });

    this.deleteActorAlloweds(this.props.drizzleState.accounts[0].toLowerCase(), address);
    this.deleteActorAllowers(address);
  }

  onSelectedActorChange(value) {
    this.setState({ allowedAddress: value });
  }

  onFormSubmit(event) {
    event.preventDefault();

    const stackId = this.contracts.ActorFactory.methods.approve.cacheSend(
      this.state.allowedAddress,
      true,
      {
        from: this.props.drizzleState.accounts[0]
      }
    );
    this.setState({
      transactionId: stackId,
      modalSuccess: true,
      modalPending: true
    });
  }

  async getUserData() {
    this.setState({ status: "initialized" });
  }

  render() {
    this.loadActorAllowedsInfo();

    if (this.state.status == "waiting" || this.state.status == "initialized") {
      return <Loading />;
    }

    const columns = [
      contentStrings.avatarColumn,
      contentStrings.nameColumn,
      contentStrings.emailColumn,
      contentStrings.countryColumn,
      contentStrings.regionColumn,
      contentStrings.typeColumn,
      contentStrings.actionsColumn
    ];
    var rows = [];
    const onTrashAllowed = this.onTrashAllowed;
    _.forEach(this.state.allowedsInfo, function(actor) {
      var currentActor = [];
      const lower = actor.typeOfActor;
      var upperAccount = actor.address.toUpperCase();
      var image = (
        <Blockies
          seed={actor.address}
          size={8}
          scale={10}
          className="identicon user-avatar rounded-circle mr-2"
        />
      );
      if (actor.image_hash != "") {
        image = (
          <img
            src={`${constants.IPFS_URL}/${actor.image_hash}`}
            width="80"
            className="user-avatar rounded-circle mr-2"
          />
        );
      }
      currentActor.push(image);
      currentActor.push(actor.name);
      currentActor.push(actor.email);
      currentActor.push(actor.country);
      currentActor.push(actor.region);
      currentActor.push(upperAccount);
      //TODO: Refactor Datatable button to remove arrow function
      currentActor.push(
        <div
          className="btn-group btn-group-sm"
          role="group"
          aria-label="Table row actions"
        >
          <DataTableButton
            id={actor.id}
            numberOfButtons="1"
            onClick={() => {
              onTrashAllowed(actor.address);
            }}
            iconClass="fas fa-trash"
          />
        </div>
      );
      rows.push(currentActor);
    });

    return (
      <>
        <Modal
          isOpen={this.state.modal}
          toggle={this.modalToggle}
          size="lg"
          className={this.props.className}
        >
          <ModalHeader toggle={this.modalToggle}>
            {this.state.modalTitle}
          </ModalHeader>
          <ModalBody>{this.state.modalBody}</ModalBody>
          <ModalFooter>
            <Button className="btn btn-accent" onClick={this.modalToggle}>
              {contentStrings.close}
            </Button>
          </ModalFooter>
        </Modal>
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-12 text-center text-sm-left mb-4 mb-sm-0">
            <span className="text-uppercase page-subtitle">
              {contentStrings.overviewTitle}
            </span>
            <h3 className="page-title">{contentStrings.permissionsTitle}</h3>
          </div>

          <div className="col-lg-12 col-md-12 mt-4">
            <div className="col-lg-12 col-md-12">
              <div className="card card-small mb-3">
                <div className="card-body">
                  <div className="">
                    <h6 className="">
                      {contentStrings.permissionsButton}
                      <i
                        className="fas fa-info-circle ml-1"
                        data-tip={contentStrings.permissionsTooltip}
                        data-place="right"
                      />
                    </h6>
                  </div>
                  <form className="add-new-post" onSubmit={this.onFormSubmit}>
                    <div className="input-group mb-3">
                      <div className="col-md-10 col-sm-10">
                        <FindActorSelect
                          onSelectedActorChange={this.onSelectedActorChange}
                          placeholder={contentStrings.findUser}
                          typeOfActor="taster"
                        />
                      </div>
                      <div className="input-group-append">
                        <button className="btn btn-accent ml-auto rounded">
                          <i className="fas fa-key fa-sidebar" />
                          <span className="ml-1">
                            {contentStrings.permissionsButton}
                          </span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12" />
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="col mb-4">
              <div className="mb-3">
                <h6 className="mt-4">{contentStrings.permissionsResume}</h6>
              </div>

              <DataTable id="currentAlloweds" columns={columns} rows={rows} />
            </div>
          </div>
          <ReactTooltip className="affogato" />
        </div>
      </>
    );
  }

}

export default withRouter(Alloweds);
import contentStrings from "../constants/localization";

export let coffeeBatchProcesses = {
  washed: contentStrings.washed,
  natural: contentStrings.natural,
  honey: contentStrings.Honey,
  semiHoney: contentStrings.semiHoney
};

export let coffeeBatchStates = {
  cherry: contentStrings.cherry,
  wet: contentStrings.wet,
  dry: contentStrings.dry,
  green: contentStrings.green,
  roasted: contentStrings.roasted
};

import React, { Component } from "react";
import { withRouter } from "react-router";
import Loading from "./Loading";
import axios from "axios";
import _ from "lodash";
import QRCode from "qrcode.react";
import ipfs from "../scripts/ipfs";
import constants from "../constants";
import contentStrings from "../constants/localization";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import qs from "qs";
import { toast } from "react-toastify";
import { css, readOnly } from "glamor";
import CheckAccountPermissions from "./CheckAccountPermissions";

import { useQuery } from "@apollo/react-hooks";
import { cupProfileByID } from "./queries/CupProfile"

function UpdateCupProfile({ cupProfileId, setStates, renderForm }){
    const { loading, error, data } = useQuery(cupProfileByID, {
        variables: { cupProfileId: cupProfileId },
        onCompleted: setStates
    });

    if (loading) return <Loading />;
    if (error) return <h2>ERROR</h2>;

    return renderForm();
}

class NewCupProfile extends Component {
  constructor(props) {
    super(props);

    this.drizzle = props.drizzle;
    this.contracts = props.drizzle.contracts;
    this.web3 = props.drizzle.web3;
    const user = JSON.parse(localStorage.getItem("user"));

    this.state = {
      accountType: user.typeOfActor,
      coffeeBatchId: null,
      owner: null,
      status: "initialized",
      profile: "",
      imageHash: "",
      cuppingNote: "",
      buffer: "",
      fileText: contentStrings.selectCupProfileImage,
      imageLoading: false,
      modal: false,
      transactionHash: "",
      modalSuccess: true,
      modalPending: true,
      modalBody: "",
      modalTitle: ""
    };


    this.setStates = this.setStates.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChangeProfile = this.onChangeProfile.bind(this);
    this.onChangeCuppingNote = this.onChangeCuppingNote.bind(this);
    this.modalToggle = this.modalToggle.bind(this);
  }

  modalToggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  componentWillUnmount() {
    if (this.props.isUpdate == null) {
      this.unsubscribe();
    }
  }

  onChangeProfile(event) {
    this.setState({ profile: event.target.value });
  }
  onChangeImage(event) {
    this.setState({ imageHash: event.target.value });
  }
  onChangeCuppingNote(event) {
    this.setState({ cuppingNote: event.target.value });
  }

  //TODO: restrict only images
  captureFile = event => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ imageLoading: true });
    var fileText = contentStrings.selectCupProfileImage;
    if (event.target.files[0] != null) {
      fileText = event.target.files[0].name;
    }

    const file = event.target.files[0];
    if (file) {
      let reader = new window.FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = () => this.convertToBuffer(reader, fileText);
    } else {
      this.setState({
        fileText: contentStrings.selectCupProfileImage,
        buffer: "",
        imageLoading: false
      });
    }
  };

  //Convert the file to buffer to store on IPFS
  convertToBuffer = async (reader, fileText) => {
    //file is converted to a buffer for upload to IPFS
    this.setState({ fileText });
    const buffer = await Buffer.from(reader.result);
    //set this buffer-using es6 syntax
    this.setState({ buffer });
  };

  async onFormSubmit(event) {
    event.preventDefault();

    if (this.state.imageLoading) {
      let toastId = toast(`🏞️ ${contentStrings.uploadingImage}`, {
        position: "bottom-right",
        autoClose: false
      });
      await ipfs.add(this.state.buffer, (err, ipfsHash) => {
        toast.update(toastId, {
          render: `☕ ${contentStrings.uploadComplete}`,
          autoClose: true,
          autoClose: 1000,
          progressClassName: css({
            background: "linear-gradient(90deg, #332211, #cc7722, #774411)"
          })
        });
        this.setState({ imageHash: ipfsHash[0].hash });
        this.saveCupProfile();
      });
    } else {
      this.saveCupProfile();
    }
  }

  saveCupProfile() {
    const cuppingNote = this.state.cuppingNote * 100;
    const stackId = this.contracts.CupProfileFactory.methods.addCupProfile.cacheSend(
      this.state.coffeeBatchId,
      this.state.profile,
      this.state.imageHash,
      cuppingNote,
      { from: this.props.drizzleState.accounts[0] }
    );
    this.setState({ transactionId: stackId });
  }

  async componentDidMount() {
    if (this.props.isUpdate == null) {
      this.setState({
          coffeeBatchId: this.props.match.params.coffeeBatchId,
          status: "complete"
      });
    }
    else{
      this.setState({
          status: "complete"
      });
    }

    const { drizzle } = this.props;

    if (this.props.isUpdate == null) {
      // subscribe to changes in the store
      this.unsubscribe = drizzle.store.subscribe(() => {
        // every time the store updates, grab the state from drizzle
        const drizzleState = drizzle.store.getState();

        // check to see if it's ready, if so, update local component state
        if (drizzleState.drizzleStatus.initialized) {
          if (drizzleState.transactionStack[this.state.transactionId]) {
            const transactionHash =
              drizzleState.transactionStack[this.state.transactionId];
            if (
              drizzleState.transactions[transactionHash].status == "pending" &&
              this.state.modalPending
            ) {
              this.setState({
                transactionHash: transactionHash,
                modal: true,
                modalTitle: contentStrings.modalSubmitedTitle,
                modalBody: contentStrings.modalSubmitedText,
                modalPending: false
              });
            }
            if (
              drizzleState.transactions[transactionHash].status == "success" &&
              this.state.modalSuccess
            ) {
              const id =
                drizzleState.transactions[transactionHash].receipt.events
                  .LogAddCupProfile.returnValues._id;

              this.setState({
                transactionHash: transactionHash,
                modal: true,
                modalTitle: contentStrings.modalSuccessTitle,
                modalBody: `${contentStrings.modalSuccessText} ${
                  this.state.transactionHash
                }`,
                modalSuccess: false
              });

              this.props.history.push(`/dashboard/cupProfiles`);
            }
          }
        }
      });
    }
  }

  setStates(data){
    let cupProfile = data.cupProfile;
    const cuppingNote = cupProfile.cuppingNote/100;

    this.setState({
        coffeeBatchId: cupProfile.coffeeBatch.id,
        profile: cupProfile.profile,
        cuppingNote: cuppingNote,
        imageHash: cupProfile.imageHash,
    });
  }

  renderForm(){
    return (
      <>
      <div className="form-group">
        <label htmlFor="">{contentStrings.profile}</label>
        <input
          type="text"
          className="form-control mb-3 "
          placeholder={contentStrings.profile}
          onChange={this.onChangeProfile}
          value={this.state.profile}
          readOnly={readOnly}
        />
      </div>
      <div className="form-group">
        <label htmlFor="">{contentStrings.cuppingNote}</label>
        <input
          type="text"
          className="form-control mb-3"
          placeholder={contentStrings.cuppingNote}
          onChange={this.onChangeCuppingNote}
          value={this.state.cuppingNote}
          readOnly={readOnly}
        />
      </div>
      <div className="form-group">
        <label htmlFor="">{contentStrings.imageColumn}</label>
        {this.props.isUpdate == null && (
          <div className="form-group custom-file">
            <input
              type="file"
              className="custom-file-input"
              onChange={this.captureFile}
              id="customFile"
            />
            <label
              className="custom-file-label"
              htmlFor="customFile"
            >
              {this.state.fileText}
            </label>
          </div>
        )}
        {this.props.isUpdate != null && this.state.imageHash != "" && (
          <div className="form-group">
            <img
              src={`${constants.IPFS_URL}/${this.state.imageHash}`}
            />
          </div>
        )}
      </div>
      </>
    );
  }

  render() {
    if (this.state.status == "waiting" || this.state.status == "initialized") {
      return <Loading />;
    }

    var saveButton = "";
    var subTitle = contentStrings.newCupProfile;

    let cupProfileForm = <></>;
    if (this.props.isUpdate != null)
        cupProfileForm = <UpdateCupProfile cupProfileId={this.props.match.params.id} setStates={this.setStates} renderForm={this.renderForm}  />;
    else{
        cupProfileForm = this.renderForm();
    }

    if (this.state.accountType != constants.ROLES.TASTER) {
      subTitle = "";
    } else {
      saveButton = (
        <button className="btn btn-accent ml-auto ">
          <i className="fas fa-coffee fa-sidebar" />
          <span className="ml-1"> {this.props.title}</span>
        </button>
      );
    }
    let readOnly = false;
    if (this.props.isUpdate) {
      readOnly = true;
    }

    //TODO: remove header and footer from data table
    return (
      <>
        <CheckAccountPermissions />
        <Modal
          isOpen={this.state.modal}
          toggle={this.modalToggle}
          size="lg"
          className={this.props.className}
        >
          <ModalHeader toggle={this.modalToggle}>
            {this.state.modalTitle}
          </ModalHeader>
          <ModalBody>{this.state.modalBody}</ModalBody>
          <ModalFooter>
            <Button className="btn btn-accent" onClick={this.modalToggle}>
              {contentStrings.close}
            </Button>
          </ModalFooter>
        </Modal>
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
            <span className="text-uppercase page-subtitle">
              {contentStrings.cupProfiles}
            </span>
            <h3 className="page-title">{this.props.title}</h3>
          </div>
        </div>
        <div>
          <div className="col-lg-12 col-md-12">
            <div className="mb-3">
              <h6 className="mt-0">{subTitle}</h6>
            </div>
            <div className="card card-small mb-3">
              <div className="card-body">
                <form className="add-new-post" onSubmit={this.onFormSubmit}>
                  {cupProfileForm}
                  {saveButton}
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12" />
        </div>
      </>
    );
  }

}

export default withRouter(NewCupProfile);

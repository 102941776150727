import gql from "graphql-tag";


const farmByID = gql`
  query farm ($farmId: ID!) {
    farm(id: $farmId ){
      id
      name
      country
      region
      village
      story
      owner{
        id
      }
    }
  }
`

const producerFarms = gql`
  query producer ($actorAccount: ID!) {
    producer(id: $actorAccount){
        id
        farms {
            id
            name
            country
            region
            village
            story
        }
    }
  }
`

const cooperativeProducerFarms = gql`
  query cooperative ($actorAccount: ID!) {
    cooperative(id: $actorAccount){
        id
        producers{
          id
          farms{
            id
            name
            country
            region
            village
            story
            owner {
                id
            }
          }
        }
    }
  }
`


export {
    farmByID,
    producerFarms,
    cooperativeProducerFarms
}
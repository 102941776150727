import React from "react";
import empty from "../assets/coffee-glass-empty.png";
import "./Loading.css";
import contentStrings from "../constants/localization";

const WrongNetworkWarning = () => (
  <div className="loading-wrapper">
    <img src={empty} alt="Empty..." className="shaking-icon" />
    <h6 className="mt-2">{contentStrings.wrongNetworkWarning}.</h6>
  </div>
);

export default WrongNetworkWarning;

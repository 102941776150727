import {db} from '../database/firebase';

export default class FirebaseDBService {

  async addAccount(address, data){
    return db.collection("accounts").doc(address).set(data)
    .then(() => 
        Promise.resolve({status: 'success'})
    )
    .catch((error) => 
       Promise.reject({status:'error', error})
    );
  }

  async getAccount(address){
    const docRef = db.collection("accounts").doc(address);
    const document = await docRef.get();
    if (document.exists){
      return Promise.resolve(document.data());
    }
    return {status:'error', error: 'Account not found'};
  }

  async updateAccount(address, data){
    return db.collection("accounts").doc(address).update(data)
    .then(() => 
        Promise.resolve({status: 'success'})
    )
    .catch((error) => 
       Promise.reject({status:'error', error})
    );
  }

  async getActorsByName(name){
    const docRef = db.collection("accounts").orderBy('name').startAt(name).endAt(name+'\uf8ff');
    const document = await docRef.get();
    if (document.docs){
      const docs = document.docs.map(doc => doc.data());
      return Promise.resolve(docs);
    }
    return {status:'error', error: 'Account not found'};
  }

  async getActorAllowers(address){
    const docRef = db.collection("accounts").where("alloweds", "array-contains", address);
    const document = await docRef.get();
    if (document.docs){
      const docs = document.docs.map(doc => doc.data());
      return Promise.resolve(docs);
    }
    return {status:'error', error: 'Account not found'};
  }

  async getActorAlloweds(address){
    const docRef = db.collection("accounts").where("allowers", "array-contains", address);
    const document = await docRef.get();
    if (document.docs){
      const docs = document.docs.map(doc => doc.data());
      return Promise.resolve(docs);
    }
    return {status:'error', error: 'Account not found'};
  }
}

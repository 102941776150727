import React, { Component } from "react";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/lib/Async";
import axios from "axios";
import _ from "lodash";
import constants from "../constants";
import FirebaseDBService from "../services/firebaseService";

const $ = require("jquery");

let typeOfActor = "";

async function loadOptions(inputValue){
  let db = new FirebaseDBService();
  let options = [];

  if (inputValue == '')
    return options;

  const actors = await db.getActorsByName(inputValue);
  actors.map(actor => (
    options.push({ value: actor.address, label: actor.name })
  ));

  return options;
};

class FindActorSelect extends Component {
  constructor(props) {
    super(props);
    typeOfActor = this.props.typeOfActor;

    this.state = {
      actorAddress: ""
    };
  }

  onActorChange = selectedOption => {
    this.setState({ selectedOption });
    if (selectedOption != null) {
      this.props.onSelectedActorChange(selectedOption.value);
      return selectedOption.value;
    } else {
      this.props.onSelectedActorChange("");
      return "";
    }
  };

  render() {
    return (
      <AsyncSelect
        cacheOptions
        isClearable
        loadOptions={loadOptions}
        defaultOptions
        isSearchable
        name="findActor"
        placeholder={this.props.placeholder}
        onChange={this.onActorChange}
      />
    );
  }
}

export default FindActorSelect;

import React, { Component } from "react";
import { withRouter } from "react-router";

//TODO: This component should be a single button
//TODO: change on click
class DataTableButton extends Component {
  handleButtonClick = () => {
    this.props.history.push(`${this.props.url}/${this.props.id}`);
  };

  render() {
    return (
      <button
        type="button"
        className="btn btn-white"
        onClick={
          this.props.onClick ? this.props.onClick : this.handleButtonClick
        }
      >
        <i className={this.props.iconClass} />
      </button>
    );
  }
}

export default withRouter(DataTableButton);

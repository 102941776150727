import FirebaseDBService from "../services/firebaseService";

export default class User {
  getData = async (userRaw, ownerAddress, drizzle) => {
    let db = new FirebaseDBService();
    const actor = await db.getAccount(ownerAddress);
    const utils = drizzle.web3.utils;
    this.typeOfActor = utils.hexToUtf8(userRaw);
    this.imageHash = actor.image_hash;
    this.name = actor.name;
  };
}

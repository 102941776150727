import { gql } from "apollo-boost";


const actorById  = gql`
  query actors($actorAccount: ID!){
    actors(id: $actorAccount){
      id
    }
  }
`


const producerById = gql`
  query producers ($actorAccount: ID!) {
    producer(id: $actorAccount ){
      id
    }
  }
`


const cooperativeProducers = gql`
  query cooperatives ($cooperativeAccount: ID!) {
    cooperative(id: $cooperativeAccount ){
      id
      producers{
          id
      }
    }
  }
`


const tasterProducers = gql`
  query tasters ($cooperativeAccount: ID!) {
    taster(id: $cooperativeAccount ){
      id
      producers{
          id
      }
    }
  }
`


const producerAlloweds = gql`
  query producer($producerAccount: ID!){
    producer(id: $producerAccount){
      id
      allowedCooperatives{
        id
      }
	  allowedTasters{
        id
      }
    }
  }
`


export {
    producerById,
    cooperativeProducers,
    producerAlloweds
}
import React, { Component } from "react";
import { withRouter } from "react-router";
import Loading from "./Loading";
import axios from "axios";
import "./NewCertificate.css";
import ipfs from "../scripts/ipfs";
import contentStrings from "../constants/localization";
import CheckAccountPermissions from "./CheckAccountPermissions";
import { toast } from "react-toastify";
import { css } from "glamor";
import qs from "qs";
import constants from "../constants";
import {
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";

//TODO: show toastify when created or updated
//TODO: Refactor name to Farm Form.
//TODO: Change url to constant
//TODO: url change depending on enviroment
//TODO: there is a weird bug display bug on change text

class NewCertificate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      imageHash: "",
      description: "",
      additionalData: "",
      certificateText: contentStrings.selectCertificateImage,
      buffer: "",
      status: "initialized",
      modal: false,
      transactionHash: "",
      modalSuccess: true,
      modalPending: true,
      modalBody: "",
      modalTitle: ""
    };

    this.contracts = props.drizzle.contracts;
    this.drizzle = props.drizzle;
    this.web3 = props.drizzle.web3;

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.modalToggle = this.modalToggle.bind(this);
  }

  modalToggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  onDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  componentDidMount() {
    const { drizzle } = this.props;
    // subscribe to changes in the store
    this.unsubscribe = drizzle.store.subscribe(() => {
      // every time the store updates, grab the state from drizzle
      const drizzleState = drizzle.store.getState();

      // check to see if it's ready, if so, update local component state
      if (drizzleState.drizzleStatus.initialized) {
        if (drizzleState.transactionStack[this.state.transactionId]) {
          const transactionHash =
            drizzleState.transactionStack[this.state.transactionId];
          if (
            drizzleState.transactions[transactionHash].status == "pending" &&
            this.state.modalPending
          ) {
            this.setState({
              transactionHash: transactionHash,
              modal: true,
              modalTitle: contentStrings.modalSubmitedTitle,
              modalBody: contentStrings.modalSubmitedText,
              modalPending: false
            });
          }
          if (
            drizzleState.transactions[transactionHash].status == "success" &&
            this.state.modalSuccess
          ) {
            const id =
              drizzleState.transactions[transactionHash].receipt.events
                .LogAddCertificate.returnValues._id;

            this.setState({
              transactionHash: transactionHash,
              modal: true,
              modalTitle: contentStrings.modalSuccessTitle,
              modalBody: `${contentStrings.modalSuccessText} ${
                this.state.transactionHash
              }`,
              modalSuccess: false
            });
            const url = `${constants.REST_URL}/actors/${
              this.props.drizzleState.accounts[0]
            }/certificates`;
            const method = "POST";
            const data = {
              id: id,
              certifier_address: this.props.drizzleState.accounts[0].toLowerCase(),
              name: this.state.name,
              image_hash: this.state.imageHash,
              description: this.state.description,
              additionalInformation: ""
            };
            const options = {
              method: method,
              headers: {
                "content-type": "application/x-www-form-urlencoded"
              },
              data: qs.stringify(data),
              url
            };
            axios(options)
              .then(response => {
                this.props.history.push("/dashboard/certificates");
              })
              .catch(function(error) {
                console.log(error);
              });
          }
        }
      }
    });
    if (this.props.isUpdate != null) {
      axios
        .get(`${constants.REST_URL}/farms/${this.props.match.params.id}`)
        .then(
          response => {
            const farm = response.data;
            this.setState({
              country: farm.country,
              region: farm.region,
              farmName: farm.name,
              story: farm.story,
              village: farm.village,
              status: "complete"
            });
          },
          error => {
            //TODO: do something with the error
            this.setState({ status: "complete" });
          }
        );
    }
  }

  //Take file input from user
  //TODO: restrict only images
  captureFile = event => {
    event.stopPropagation();
    event.preventDefault();
    var certificateText = contentStrings.selectCertificateImage;
    if (event.target.files[0] != null) {
      certificateText = event.target.files[0].name;
    }

    // this.setState({ certificateText: "Loading..." });
    const file = event.target.files[0];
    let reader = new window.FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = () => this.convertToBuffer(reader, certificateText);
  };

  //Convert the file to buffer to store on IPFS
  convertToBuffer = async (reader, certificateText) => {
    //file is converted to a buffer for upload to IPFS
    this.setState({ certificateText });
    const buffer = await Buffer.from(reader.result);
    //set this buffer-using es6 syntax
    this.setState({ buffer });
  };

  //TODO: On ifps submit show indicator that is loading
  onFormSubmit = async event => {
    event.preventDefault();
    let toastId = toast(`🏞️ ${contentStrings.uploadingImage}`, {
      position: "bottom-right",
      autoClose: false
    });
    await ipfs.add(this.state.buffer, (err, ipfsHash) => {
      toast.update(toastId, {
        render: `☕ ${contentStrings.uploadComplete}`,
        autoClose: true,
        autoClose: 1000,
        progressClassName: css({
          background: "linear-gradient(90deg, #332211, #cc7722, #774411)"
        })
      });
      this.setState({ imageHash: ipfsHash[0].hash });
      const stackId = this.contracts.CertificateFactory.methods.addCertificate.cacheSend(
        this.web3.utils.utf8ToHex(this.state.name),
        this.state.imageHash,
        this.state.description,
        this.state.additionalData,
        { from: this.props.drizzleState.accounts[0] }
      );
      this.setState({ transactionId: stackId });
    });
  };

  render() {
    if (this.state.certificates == null && this.props.isUpdate != null) {
      switch (this.state.status) {
        case "complete":
          break;
        default:
          return <Loading />;
          break;
      }
    }

    return (
      <>
        <CheckAccountPermissions />
        <Modal
          isOpen={this.state.modal}
          toggle={this.modalToggle}
          size="lg"
          className={this.props.className}
        >
          <ModalHeader toggle={this.modalToggle}>
            {this.state.modalTitle}
          </ModalHeader>
          <ModalBody>{this.state.modalBody}</ModalBody>
          <ModalFooter>
            <Button className="btn btn-accent" onClick={this.modalToggle}>
              {contentStrings.close}
            </Button>
          </ModalFooter>
        </Modal>
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
            <span className="text-uppercase page-subtitle">
              {contentStrings.certificates}
            </span>
            <h3 className="page-title">{this.props.title}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card card-small mb-3">
              <div className="card-body">
                <form className="add-new-post" onSubmit={this.onFormSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder={contentStrings.certificateName}
                      value={this.state.name}
                      onChange={this.onNameChange}
                    />
                  </div>
                  <div className="form-group custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      onChange={this.captureFile}
                      id="customFile"
                    />

                    <label className="custom-file-label" htmlFor="customFile">
                      {this.state.certificateText}
                    </label>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control "
                      placeholder={contentStrings.descriptionColumn}
                      onChange={this.onDescriptionChange}
                      value={this.state.description}
                    />
                  </div>

                  <button className="btn btn-accent ml-auto ">
                    <i className="fas fa-award fa-sidebar" />
                    <span className="ml-1"> {this.props.title}</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-12" />
        </div>
      </>
    );
  }
}

export default withRouter(NewCertificate);

import React, { Component } from "react";
import coffee from "../assets/coffee.jpg";
import QRCode from "qrcode.react";
import Moment from "react-moment";
import "./CoffeeCard.css";
import contentStrings from "../constants/localization";
import Loading from "./Loading";
import { coffeeBatchProcesses, coffeeBatchStates } from "../constants/choices";
import Blockies from "react-blockies";
import constants from "../constants";
import { InputGroupAddon, Input, InputGroup, Button } from "reactstrap";
import copy from "copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import { Flip } from "react-toastify";
import { css, target } from "glamor";
import FirebaseDBService from "../services/firebaseService";


class CoffeeCard extends Component {
  constructor(props) {
    super(props);
    this.db = new FirebaseDBService();

    this.state = {
      farmer: null,
      status: 'initialized'
    };

    this.copyText = this.copyText.bind(this);
  }

  copyText() {
    copy(this.props.farmer.address);
    toast(`📋 ${contentStrings.copyMessage}`, {
      progressClassName: css({
        background: "linear-gradient(90deg, #332211, #cc7722, #774411)"
      })
    });
  }

  async loadProducerData(){
    const account = this.props.farmer.id;
    const actor = await this.db.getAccount(account);

    if (actor.error) {
      console.error(`Error retrieving account: ${account}`);
    }

    this.setState({
      farmer: actor,
      status: 'complete'
    });
  }


  render() {
    this.loadProducerData();

    if (this.state.status == 'initialized')
        return <Loading />;

    var cupProfiles = null;
    if (this.props.cupProfiles != null) {
      cupProfiles = this.props.cupProfiles[0];
    }

    return (
      <div className="card card-small user-details mb-4">
        <div className="card-header p-0">
          <div className="user-details__bg">
            <img src={coffee} alt="Coffee Details Background Image" />
          </div>
        </div>
        <div className="card-body p-0 pt-3">
          <div className="user-details__avatar mx-auto">
            {this.state.farmer.image_hash != "" ? (
              <img
                src={`${constants.IPFS_URL}/${this.state.farmer.image_hash}`}
                width="80"
                className="user-avatar rounded-circle mr-2"
              />
            ) : (
              <Blockies
                seed={this.state.farmer.address}
                size={8}
                scale={10}
                className="identicon user-avatar rounded-circle mr-2"
              />
            )}
          </div>
          <div className="user-details__user-data p-4 text-center">
            <div className="row mb-3">
              <div className="col w-50">
                <span>{contentStrings.farmer}</span>
                <span>{this.state.farmer.name} </span>
              </div>
            </div>
            {this.props.farmer.bio != null && (
              <div className="row mb-3">
                <div className="col">
                  <span>{contentStrings.shortBio}</span>
                  <span>{this.state.farmer.bio}</span>
                </div>
              </div>
            )}
            <div className="row mb-3">
              <div className="col">
                <span>{contentStrings.regionColumn}</span>
                <span>
                  {this.state.farmer.region}, {this.state.farmer.country}
                </span>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <span>{contentStrings.contactInformation}</span>
                <span>{this.state.farmer.email}</span>
              </div>
            </div>
              <>
                <div className="row mb-3">
                  <div className="col">
                    <span>{contentStrings.accountAddress}</span>
                    <span>
                      <InputGroup>
                        <InputGroupAddon addonType="append" className="mx-auto">
                          <Input readOnly value={this.state.farmer.address} />
                          <Button
                            className="btn btn-accent"
                            onClick={this.copyText}
                          >
                            {contentStrings.copy}
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </span>
                  </div>
                </div>
                <div className="user-details__tags  text-center m-2 mt-2">
                  <QRCode
                    id="downloadCanvas"
                    size={230}
                    value={this.state.farmer.address}
                    className="m-3"
                  />
                </div>
                <div className="user-details__tags  text-center m-2 mt-2">
                  <a
                    className="btn burner-button "
                    href={`https://xdai.io/${
                      this.state.farmer.address
                    };1;tip%20the%20farmer`}
                    target="blank"
                  >
                    <i className={`fas fa-fire ${this.props.iconClass}`} />{" "}
                    {contentStrings.tipBurner}
                  </a>
                </div>
              </>
          </div>

          <div className="user-details__user-data border-top border-bottom p-4 text-center">
            <div className="row mb-3">
              <div className="col w-50">
                <span>{contentStrings.farmName}</span>
                <span>{this.props.farm.name}</span>
              </div>
              <div className="col w-50">
                <span>{contentStrings.regionColumn}</span>
                <span>
                  {this.props.farm.village}, {this.props.farm.region}
                </span>
              </div>
              <iframe
                className="mt-2 mx-auto px-4 embed-responsive"
                frameBorder="0"
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAkqEYG5LG4ZgOzTK6O7yY-K9manVHQrfk
&q=${this.props.farm.village}+${this.props.farm.region}&zoom=6`}
                allowFullScreen
              />
            </div>

            <div className="row mb-3">
              <div className="col w-50">
                <span>{contentStrings.variety}</span>
                <span>{this.props.coffeeBatch.variety} </span>
              </div>
              <div className="col w-50">
                <span>{contentStrings.process}</span>
                <span>
                  {coffeeBatchProcesses[this.props.coffeeBatch.process]}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col w-50">
                <span>{contentStrings.batchSize}</span>
                <span>
                  {this.props.coffeeBatch.size} {contentStrings.ql}
                </span>
              </div>
              <div className="col w-50">
                <span>{contentStrings.altitude}</span>
                <span>
                  {this.props.coffeeBatch.altitude} {contentStrings.MAMSL}
                </span>
              </div>
              <div className="row ">
                <div className="col mx-4">
                  <span>{contentStrings.farmStory}</span>
                  <span>{this.props.farm.story}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer transition={Flip} />
      </div>
    );
  }
}

export default CoffeeCard;

import React, { Component } from "react";
import DataTable from "./DataTable";
import EmptyDashboardTable from "./EmptyDashboardTable";
import LinkButton from "./LinkButton";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import _ from "lodash";
import axios from "axios";
import QRCode from "qrcode.react";
import Modal from "react-awesome-modal";
import copy from "copy-to-clipboard";
import "./CoffeeBatches.css";
import constants from "../constants";
import contentStrings from "../constants/localization";
import { connect } from "react-redux";
import CheckAccountPermissions from "./CheckAccountPermissions";
import { coffeeBatchProcesses, coffeeBatchStates } from "../constants/choices";
import {
  Label,
  Modal as InputModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";

import { useQuery } from "@apollo/react-hooks";
import {
  coffeeBatchesByProducer,
  cooperativeProducersCoffeeBatches
} from "./queries/CoffeeBatch";

function LoadCoffeeBatches({
  accountAddress,
  isCooperative,
  openModal,
  openInputModal
}) {
  const columns = [
    contentStrings.qrCode,
    contentStrings.farm,
    contentStrings.variety,
    contentStrings.altitude,
    contentStrings.process,
    contentStrings.batchSize,
    contentStrings.actionsColumn
  ];
  const rows = [];
  var linkUrl = `/dashboard/coffeeBatches/edit/`;

  let graphQuery = coffeeBatchesByProducer;
  if (isCooperative) {
    graphQuery = cooperativeProducersCoffeeBatches;
    linkUrl = `/dashboard/producers/coffeeBatches/edit/`;
  }

  const { loading, error, data } = useQuery(graphQuery, {
    variables: { actorAccount: accountAddress }
  });

  if (loading) return <Loading />;
  if (error) return <h2>ERROR</h2>;

  let actor = null;
  if (isCooperative) actor = data.cooperative;
  else actor = data.producer;

  if (actor != null) {
    if (isCooperative && actor.producers != null)
      actor.producers.map(producer =>
        producer.coffeeBatches.map(coffeeBatch =>
          rows.push(
            getCoffeeBatchRow({
              coffeeBatch,
              openModal,
              openInputModal,
              linkUrl,
              isCooperative,
              accountAddress
            })
          )
        )
      );
    else
      actor.coffeeBatches.map(coffeeBatch =>
        rows.push(
          getCoffeeBatchRow({ coffeeBatch, openModal, linkUrl, isCooperative })
        )
      );

    return <DataTable id="currentBatches" columns={columns} rows={rows} />;
  } else
    return (
      <EmptyDashboardTable
        title={contentStrings.coffeeBatches}
        titleSingular={contentStrings.coffeeBatch}
        url="coffeeBatches"
      />
    );
}

function getCoffeeBatchRow({
  coffeeBatch,
  openModal,
  openInputModal,
  linkUrl,
  isCooperative,
  accountAddress
}) {
  let currentCoffeeBatch = [];
  let currentUrl = linkUrl + `${coffeeBatch.id}`;

  currentCoffeeBatch.push(
    <div className="mt-2">
      <a
        href="#"
        value="1"
        onClick={() => {
          openModal(coffeeBatch.id);
        }}
      >
        <QRCode
          size={80}
          value={`${constants.WEBSITE_URL}/coffeeBatches/${coffeeBatch.id}`}
        />
      </a>
    </div>
  );
  currentCoffeeBatch.push(coffeeBatch.farm.name);
  currentCoffeeBatch.push(coffeeBatch.variety);
  currentCoffeeBatch.push(coffeeBatch.altitude);
  currentCoffeeBatch.push(coffeeBatchProcesses[coffeeBatch.process]);
  currentCoffeeBatch.push(coffeeBatch.size / 100);
  if (isCooperative) {
    currentCoffeeBatch.push(
      <>
        <LinkButton
          url={currentUrl}
          iconClass={"fas fa-edit mr-2"}
          caption={contentStrings.edit}
        />{" "}
        <div
          className="btn-group btn-group-sm"
          role="group"
          aria-label="Table row actions"
        >
          <button
            type="button"
            className="btn btn-accent form-control"
            onClick={() => {
              openInputModal(coffeeBatch.farm.owner.id, coffeeBatch);
            }}
          >
            <i className="fab fa-ethereum mr-2" /> {contentStrings.createToken}
          </button>
        </div>
      </>
    );
  } else {
    currentCoffeeBatch.push(
      <LinkButton url={currentUrl} iconClass={"fas fa-edit"} />
    );
  }

  return currentCoffeeBatch;
}

async function createToken(
  producerAccount,
  coffeeBatch,
  coffeeAmount,
  drizzle,
  accountAddress
) {
  console.log("TCL: coffeeBatch", coffeeBatch);
  let tokenUri = {
    name: `${coffeeBatch.variety} ${coffeeBatch.process}`,
    image: "", //TODO: set image

    properties: coffeeBatch
  };
  console.log("TCL: tokenUri", tokenUri);
  const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
  console.log(drizzle);
  const response = await axios.post(url, tokenUri, {
    headers: {
      pinata_api_key: constants.PINATA_KEY,
      pinata_secret_api_key: constants.PINATA_SECRET
    }
  });
  drizzle.contracts.ERC1155.methods.create.cacheSend(
    response.data.IpfsHash,
    producerAccount,
    coffeeAmount,
    { from: accountAddress }
  );
}

class CoffeeBatches extends Component {
  constructor(props) {
    super(props);

    this.drizzle = props.drizzle;
    this.contracts = props.drizzle.contracts;
    this.web3 = props.drizzle.web3;
    const user = JSON.parse(localStorage.getItem("user"));

    this.state = {
      accountAddress: this.props.drizzleState.accounts[0].toLowerCase(),
      coffeeBatches: null,
      isLightboxOpen: false,
      lightboxQR: null,
      isModalOpen: false,
      currentProducerAccount: "",
      currentCoffeeBatchId: 0,
      tokenAmount: "",
      selectedCoffeeBatch: 0,
      status: "initialized",
      accountType: user.typeOfActor
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openInputModal = this.openInputModal.bind(this);
    this.onTokenAmountChange = this.onTokenAmountChange.bind(this);
    this.onCreateToken = this.onCreateToken.bind(this);
    this.closeInputModal = this.closeInputModal.bind(this);
    this.downloadQR = this.downloadQR.bind(this);
    this.copyText = this.copyText.bind(this);
  }

  openModal(coffeeBatch) {
    this.setState({
      isLightboxOpen: true,
      selectedCoffeeBatch: coffeeBatch
    });
  }

  closeModal() {
    this.setState({
      isLightboxOpen: false
    });
  }

  openInputModal(account, coffeeBatch) {
    this.setState({
      isModalOpen: true,
      currentProducerAccount: account,
      currentCoffeeBatch: coffeeBatch
    });
  }

  closeInputModal() {
    this.setState({
      isModalOpen: false,
      currentProducerAccount: "",
      currentCoffeeBatch: null,
      tokenAmount: ""
    });
  }

  onTokenAmountChange(event) {
    this.setState({
      tokenAmount: event.target.value
    });
  }

  onCreateToken() {
    if (
      this.state.tokenAmount > 0 &&
      this.state.currentProducerAccount != "" &&
      this.state.currentCoffeeBatchId != null
    ) {
      createToken(
        this.state.currentProducerAccount,
        this.state.currentCoffeeBatch,
        this.state.tokenAmount,
        this.props.drizzle,
        this.state.accountAddress
      );
      this.closeInputModal();
    }
  }

  //TODO: change download name
  downloadQR() {
    var canvas = document.getElementById("downloadCanvas");
    var img = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    window.location.href = img;
    this.closeModal();
  }

  copyText() {
    const copyUrl = `${constants.WEBSITE_URL}/coffeeBatches/${
      this.state.selectedCoffeeBatch
    }`;
    copy(copyUrl);
    //TODO: show toastify
    this.closeModal();
  }

  componentDidMount() {
    this.setState({ status: "complete" });
  }

  //TODO: row props not changing on update
  render() {
    let accountType = this.state.accountType;

    if (this.state.status == "waiting") {
      return <Loading />;
    }

    let coffeeBatchesTable;
    var isCooperative = accountType == constants.ROLES.COOPERATIVE;

    return (
      <>
        <CheckAccountPermissions />
        <div className="page-header row no-gutters py-4">
          <div className="col-12 col-sm-4 text-center text-sm-left mb-4 mb-sm-0">
            <span className="text-uppercase page-subtitle">
              {contentStrings.overviewTitle}
            </span>
            <h3 className="page-title">{contentStrings.coffeeBatchTitle}</h3>
          </div>
          <div className="offset-sm-6 col-4 d-flex col-12 col-sm-2 d-flex align-items-center float-right">
            <div className="input-daterange  input-group-sm ml-auto">
              {!isCooperative ? (
                <LinkButton
                  url={"/dashboard/coffeeBatches/new"}
                  iconClass={"fas fa-seedling mr-2"}
                  caption={contentStrings.newCoffeeBatch}
                />
              ) : (
                <div />
              )}
            </div>
          </div>

          <div className="row">
            <div className="col mb-4">
              <div className="mb-3">
                <h6 className="mt-4">{contentStrings.resumeCoffeeBatches}</h6>
              </div>

              <LoadCoffeeBatches
                accountAddress={this.state.accountAddress}
                isCooperative={isCooperative}
                openModal={this.openModal}
                openInputModal={this.openInputModal}
              />
            </div>
          </div>

          <Modal
            visible={this.state.isLightboxOpen}
            width="400"
            height="400"
            effect="fadeInUp"
            onClickAway={this.closeModal}
          >
            <div className="modalDiv">
              <QRCode
                id="downloadCanvas"
                size={400}
                value={`${constants.WEBSITE_URL}/coffeeBatches/${
                  this.state.selectedCoffeeBatch
                }`}
              />
              <a href="#" className="modalLink" onClick={this.copyText}>
                <i className="fas fa-copy mr-2" />
                {contentStrings.copy}
              </a>
              <a href="#" className="modalLink ml-3" onClick={this.downloadQR}>
                <i className="fas fa-download mr-2" />
                {contentStrings.download}
              </a>
              <a href="#" className="modalLink ml-3" onClick={this.closeModal}>
                <i className="fas fa-window-close mr-2" />
                {contentStrings.close}
              </a>
            </div>
          </Modal>

          <InputModal isOpen={this.state.isModalOpen}>
            <ModalHeader toggle={this.toggle}>
              {contentStrings.createToken}
            </ModalHeader>
            <ModalBody>
              <div className="form-group">
                {/* //  ! Coffee Amount can't be greater than coffee batch
                 */}
                <Label for="">{contentStrings.coffeeAmount}</Label>
                <input
                  type="text"
                  className="form-control"
                  id=""
                  placeholder={contentStrings.tokenAmount}
                  onChange={this.onTokenAmountChange}
                  value={this.state.tokenAmount}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button className="btn-accent" onClick={this.onCreateToken}>
                <i className="fab fa-ethereum mr-2" />
                {contentStrings.createToken}
              </Button>{" "}
              <Button className="secondary" onClick={this.closeInputModal}>
                <i className="fas fa-window-close mr-2" /> Cancel
              </Button>
            </ModalFooter>
          </InputModal>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return { store: state };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(CoffeeBatches)
);
